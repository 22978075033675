'use strict';

define('vb/private/stateManagement/container',[
  'ojs/ojcontext',
  'ojs/ojrouter',
  'vb/private/action/actionChainUtils',
  'vb/private/action/assignmentHelper',
  'vb/private/stateManagement/redux/storeManager',
  'vb/private/stateManagement/scope',
  'vb/private/stateManagement/router',
  'vb/private/stateManagement/navigationContext',
  'vb/private/log',
  'vbc/private/logConfig',
  'vb/private/stateManagement/stateUtils',
  'vb/private/translations/bundlesModel',
  'vb/private/constants',
  'vb/private/utils',
  'vb/errors/httpError',
  'vb/private/helpers/eventHelper',
  'vb/private/history',
  'vb/private/stateManagement/scopeResolver',
  'vb/private/model/modelUtils',
  'vb/private/events/eventRegistry',
  'vb/private/configLoader',
  'vb/private/debug/applicationDebugStream',
  'vbc/private/performance/performance',
  'vb/private/events/eventMonitorOptions',
  'urijs/URI',
], (ojContext, ojRouter, ActionChainUtils, AssignmentHelper, StoreManager, Scope,
  Router, NavigationContext, Log, LogConfig, StateUtils,
  BundlesModel, Constants, Utils, HttpError, EventHelper, History, ScopeResolver,
  ModelUtils, EventRegistry, ConfigLoader, ApplicationDebugStream, Performance,
  EventMonitorOptions, URI) => {
  const logger = Log.getLogger('/vb/stateManagement/container', [
    // Register custom loggers
    {
      name: 'beforeHandleEvent',
      severity: Constants.Severity.INFO,
      style: LogConfig.FancyStyleByFeature.containerStart,
    },
    {
      name: 'afterHandleEvent',
      severity: Constants.Severity.INFO,
      style: LogConfig.FancyStyleByFeature.containerEnd,
    },
  ]);

  /**
   * An error to be return when the container property is not subclassed
   *
   * @param  {String}  propName  the name of the property to be define
   * @return {Error}  the error message.
   */
  const undefinedPropertyError = (propName) => new Error(`Containers must define the ${propName} property.`);

  /**
   * An error to be return when the container method is not subclassed
   *
   * @param  {String}  methodName  the name of the property to be define
   * @return {Error}  the error message.
   */
  const undefinedMethodError = (methodName) => new Error(`Containers must implement ${methodName}.`);

  /**
   * A base class for containers that require variable and action chain support. Classes in brackets are mixins
   *
   *
   *                                             Container
   *                                                 |
   *           +-------------------------------------+-------------------------------------------+
   *           |                                     |                                           |
   *         Flow                             (FragmentHolder)                         ContainerExtension
   *           |                                     |                                           |
   *     +-----+-----+                +--------------+-------------+                  +----------+----------+
   *     |           |                |              |             |                  |                     |
   * Application PackageFlow         Page         Fragment       Layout        FlowExtension   (FragmentHolderExtension)
   *                 |                |              |                                |                     |
   *             AppPackage    PageInExtension PackageFragment                        |                     |
   *                                  |                                    +----------+---------+           |
   *                              PackagePage                              |                    |           |
   *                                                             ApplicationExtension  AppPackageExtension  |
   *                                                                                                        |
   *                                                                                                        |
   *                                                                      +-----------------+---------------+
   *                                                                      |                 |               |
   *                                                                PageExtension   LayoutExtension  FragmentExtension
   */
  class Container {
    /**
     * Constructs a Container
     *
     * @param  {String}  id
     * @param  {Container}  parent
     * @param  {String} [className='Container']  The class name
     */
    constructor(id, parent, className = 'Container') {
      /**
       * Initialize the static property '__application' with the Application instance
       * That is used for the value the application property for all containers
       */
      if (className === 'Application' && !Container[Constants.APP_INTERNAL_PROPERTY_NAME]) {
        Container[Constants.APP_INTERNAL_PROPERTY_NAME] = this;
      }

      /**
       * @type {String}
       */
      this._id = id;

      /**
       * Hold the class name, 'Application', 'Flow', 'Page', etc...
       * @type {String}
       */
      this._className = className;

      /**
       * Sets the parent container. For a page, it's a flow, for a flow
       * it's a page, for Application it is null.
       * @type {Container}
       */
      this._parent = parent;

      /**
       * For base object application, there is no extension so we use duckTyping of an empty extension.
       * @type {Object}
       */
      this._extension = Container.createEmptyExtension();

      /**
       * Initialize a variable with the page path so we don't recalculate it all the time
       * @type {String}
       */
      this.fullPath = this.getContainerPath();

      /**
       * The path of this container relative to the application,
       * something like: 'flows/main/pages/main-start'
       * Each container implementation is responsible to set this property.
       * @type {String}
       */
      this.path = null;

      /**
       * The scope used to store variables
       * @type {Scope}
       */
      this.scope = null; // Initialized in initVariableScope()

      /**
       * @type {ojRouter}
       */
      this.router = null; // Initialized in initRouter()
      /**
       * The entire set of Context objects, ($application, and $page, and $flow, etc)
       * @type {Object} availableContexts
       */
      this.availableContexts = null; // Initialized in getAvailableContexts()
      this.expressionContext = null; // the single Context object, for expressions ($page OR $flow OR $chain, etc)
      this.functions = null;
      this.variablesListeners = [];
      this.chains = {}; // initialized in initializeActionChains
      this.lifecycleState = Constants.ContainerState.CREATED;
      this.log = logger;
      this.eventListeners = {}; // initialized in initializeComponentListeners
      this.enums = {}; // initialized in initializeEnums

      this.bundles = undefined; // we will set this to null if we load the descriptor, nd none are defined

      this.loadFunctionsPromise = null;
      this.loadMetadataPromise = null;
      this.loadDescriptorPromise = null;
      this.loadBundlesPromise = null;

      this.exported = null; // defined in get export()

      // The array of all extension object that apply to this container
      this.extensionsArray = [];

      // A map of all extension that apply to this container
      this.extensions = {};

      // A map of all imports that apply to this container
      this.imports = {};

      /**
       * The metadata loaded from the .json
       * Defined in initDefault as a read-only property after the metadata is loaded
       * @type {Object}
       */
      this.definition = null;

      this.layouts = []; // All the layout used in this container

      this.navPath = null; // defined in getNavPath()

      /**
       * The instances of the loaded flows used by this container (flow and appPackage)
       * @type {Object<String, Flow>}
       */
      this.flows = {};
    }

    /**
     * Create an empty extension that is used by the extension property definition in the constructor.
     * Every container has an extension property value but for base container that do not extend anything,
     * we needed an object representing a non-extension for the id. baseUrl and getAbsoluteUrl call.
     * It is also used by tests to alter the location of the application by changing the
     * baseUrl property (see TestUtils.js).
     * @return {Object}
     */
    // eslint-disable-next-line class-methods-use-this
    static createEmptyExtension() {
      return {
        // Use "base" for the id of the potential extension of this container
        id: Constants.ExtensionFolders.BASE,
        // ConfigLoader.requirePath is used by tests to change the base url of the app without
        // touching the requirejs baseUrl that point to the tests classes.
        // For an app not in the testing environment, ConfigLoader.requirePath is null
        baseUrl: ConfigLoader.requirePath || '',
        files: [],
        getAbsoluteUrl() {
          return requirejs.toUrl('');
        },
        fileExists() {
          return true;
        },
      };
    }

    /**
     * @type {String}
     * @readonly
     */
    get id() {
      return this._id;
    }

    /**
     * The default name is the id, subclass change that
     * @type {String}
     */
    get name() {
      return this._id;
    }

    /**
     * @type {String}
     * @readonly
     */
    get className() {
      return this._className;
    }

    /**
     * @type {Container}
     * @readonly
     */
    get parent() {
      return this._parent;
    }

    /**
     * @type {Application}
     * @readonly
     */
    // eslint-disable-next-line class-methods-use-this
    get application() {
      return Container[Constants.APP_INTERNAL_PROPERTY_NAME];
    }

    /**
     * The extension object this resource is coming from.
     *
     * @type {Object}
     */
    get extension() {
      return this._extension;
    }

    /**
     * For application where the extension is empty, the id is 'base'.
     * It is needed to define the path of the object extending this container.
     * It is used to defined
     * @type {String}
     */
    get extensionId() {
      return this.extension.id;
    }

    /**
     * The path to prefix to the resource path in order to reach it in the extension manager.
     * In the case of an application resource the extension is empty and the baseUrl is '',
     * but for App UI, the baseUrl is the extension base URL
     *
     * @type {String}
     */
    get baseUrl() {
      return this.extension.baseUrl;
    }

    /**
     * The id in the URL is by default the same as the container id
     * @type {!String}
     */
    get urlId() {
      return this._id;
    }

    /**
     * The location of the resources relative to the application.
     * It is used in getResourceFolder() that is used to build the path to load the resource.
     * This value is modified by appPackage where the file structure is different.
     * A page is an application "flows/main/pages/"
     * Same page in an appPackage is "ui/self/applications/appPack1/flows/main/pages/"
     * Note that this is using a getter because the value of path is defined later in the
     * subclass constructor
     * @type {String}
     */
    get resourceLoc() {
      return this.path;
    }

    /**
     * Used to define the value of the path builtin variable on $extension
     * this is to access images resource that are not bundled with the extension.
     * @type {String}
     */
    get absoluteUrl() {
      return this.extension.getAbsoluteUrl();
    }

    /**
     * @type {String}
     * @readonly
     */
    get eventPrefix() {
      // By default the event prefix is the class
      return this._className.toLowerCase();
    }

    /**
     * The name of the span used when loading the resource
     * Names can only be ones of the list in MonitorOptions class
     *
     * @type {String}
     */
    get loadSpanName() {
      return `${this.className}Load`;
    }

    /**
     * The name of the span used when variable are activated
     * Names can only be ones of the list in MonitorOptions class
     *
     * @type {String}
     */
    get activateSpanName() {
      return `${this.className}Activate`;
    }

    /**
     * Subclasses can override to provide the actual name of the resource that this container represents.
     *
     * @type {String}
     * @readonly
     */
    get resourceName() {
      return this.name;
    }

    /**
     * this base implementation should not be called; make it obvious if it happens during development
     * @type {String}
     * @readonly
     */
    // eslint-disable-next-line class-methods-use-this
    get fullName() {
      throw undefinedPropertyError('fullName');
    }

    /**
     * The scope resolver is an object to retrieve scope instances given their names.
     * This is used to resolve the scope name of expressions like scopeName:typeName.
     * When defined, possible scope names are: "this", "page", "flow" or "application".
     * Cannot be in the defineProperties above because getScopeResolverMap() uses this.parent
     * @type {ScopeResolver}
     */
    get scopeResolver() {
      // Uses the lazy value pattern. The scope resolver is initialized only the first time
      // it is accessed then the property becomes read-only with the finalValue.

      // Merge the 'this' property with the scope resolver map
      const finalValue = new ScopeResolver(
        Object.assign({ [Constants.THIS_PREFIX]: this }, this.getScopeResolverMap()),
      );

      // Once the final value is calculated, redefine the property as a value, so the
      // next time it is read, the value will be returned immediately.
      Object.defineProperty(this, 'scopeResolver', {
        value: finalValue,
        configurable: true,
      });

      return finalValue;
    }

    /**
     * The name of the runtime environment function to be used to load the descriptor
     *
     * @type {String} the descriptor loader function name
     */
    static get descriptorLoaderName() {
      throw undefinedPropertyError('descriptorLoaderName');
    }

    /**
     * The name of the runtime environment function to be used to load the module functions
     *
     * @type {String} the module loader function name
     */
    static get functionsLoaderName() {
      throw undefinedPropertyError('functionsLoaderName');
    }

    /**
     * The name of the runtime environment function to be used to load the html
     *
     * @type {String} the template loader function name
     */
    static get templateLoaderName() {
      throw undefinedPropertyError('templateLoaderName');
    }

    /**
     * Load the descriptor using the loader in the RuntimeEnvironment
     *
     * @return {Object} the descriptor object
     */
    descriptorLoader(resourceLocator) {
      return this.application.runtimeEnvironment[this.constructor.descriptorLoaderName](resourceLocator);
    }

    /**
     * Load the module functions using the loader in the RuntimeEnvironment
     *
     * @return {Object} the module object
     */
    functionsLoader(resourceLocator) {
      return this.application.runtimeEnvironment[this.constructor.functionsLoaderName](resourceLocator);
    }

    /**
     * Load the html content using the loader in the RuntimeEnvironment
     *
     * @return {Object} the module object
     */
    templateLoader(resourceLocator) {
      return this.application.runtimeEnvironment[this.constructor.templateLoaderName](resourceLocator);
    }

    /**
     * used by events, overridden by containerExtension
     * @returns {boolean}
     */
    // eslint-disable-next-line class-methods-use-this
    isExtension() {
      return false;
    }

    /**
     * Return the require path to the resource.
     * @return {String}
     */
    getResourceFolder() {
      return `${this.baseUrl}${this.resourceLoc}`;
    }

    /**
     * Return the require path with the name of the resource
     * @return {String}
     */
    getResourcePath() {
      return `${this.getResourceFolder()}${this.resourceName}`;
    }

    /**
     * Returns a scope resolver map where keys are scope name ("page", "flow" or "application")
     * and value the matching objects. This is used to build the scopeResolver object.
     *
     * @return {Object<String, Object>} an object which properties are scope
     */
    // eslint-disable-next-line class-methods-use-this
    getScopeResolverMap() {
      throw undefinedMethodError('getScopeResolverMap');
    }

    /**
     * returns a Services object specific to this flow
     * @return {Services}
     */
    // eslint-disable-next-line class-methods-use-this
    getServices() {
      throw undefinedMethodError('getServices');
    }

    /**
     * returns a list of Services, in priority order, high to low
     * Containers may override to provide different access to containers services, if needed
     *
     * We call application.getAllServices to include extensions
     * @return {Array<Services>}
     */
    getAllServices() {
      return Utils.toFlatUniqueArray(this.getServices(), this.application.getAllServices());
    }

    /**
     * Calling context of a container
     * @typedef {Object} CallingContext
     * @property {Extension} sourceExtension Extension that is used to resolve endpoints referenced by a container
     */

    /**
     * Returns object containing the calling context of the container.
     *
     * @return {CallingContext}
     */
    getCallingContext() {
      return {
        sourceExtension: this.extension,
      };
    }

    /**
     * Called by the router in response to the enter callback
     * Implemented by Page and Flow
     *
     * @return {Promise}
     */
    // eslint-disable-next-line class-methods-use-this
    enter() {
      throw undefinedMethodError('enter');
    }

    /**
     * Called by the router in response to the exit callback
     * Implemented by Page and Flow
     *
     * @return {Promise}
     */
    // eslint-disable-next-line class-methods-use-this
    exit() {
      throw undefinedMethodError('exit');
    }

    /**
     * Create a child router of the parent container router.
     * Uses the id for the router name and for the parent state id. This will
     * allow the traversal of the router hierarchy to retrieve the leaf page.
     *
     * @return {ojRouter} the router for this container
     */
    createRouter(parent) {
      if (!parent.router) {
        return this.createRouter(parent.parent);
      }

      const childRouter = parent.router.getChildRouter(this.urlId);
      if (childRouter) {
        return childRouter;
      }

      return parent.router.createChildRouter(this.urlId, this.urlId);
    }

    initRouter() {
      if (this.router) {
        return;
      }
      const router = this.createRouter(this.parent);
      if (router) {
        // Make the router property readonly
        Object.defineProperty(this, 'router', {
          value: router,
          enumerable: true,
          configurable: true,
        });
        // Configure the router using a "state from id" callback
        this.router.configure(this.getRouterConfigureCallBack());
      }
    }

    /**
     * The enter callback for ojRouter state transition used the
     * getRouterConfigureCallBack below
     * @param  {String} stateId
     * @return {Promise}
     */
    routerStateEnterCallback(stateId) {
      return Promise.resolve().then(() => {
        // Call getContainer instead of loadContainer because the container has
        // been created in canEnter.
        const cont = this.getContainer(stateId);

        return cont && cont.enter();
      });
    }

    /**
     * The canExit callback for ojRouter state transition used by the
     * getRouterConfigureCallBack below
     *
     * @param  {String}  containerId  The container identifier
     * @return {Promise}
     */
    routerStateCanExitCallback(containerId) {
      return Promise.resolve().then(() => {
        const cont = this.getContainer(containerId);

        return cont && cont.canExit();
      });
    }

    /**
     * The exit callback for ojRouter state transition used by the
     * getRouterConfigureCallBack below
     *
     * @param  {String} stateId
     * @return {Promise}
     */
    routerStateExitCallback(stateId) {
      return Promise.resolve().then(() => {
        const cont = this.getContainer(stateId);

        return cont && cont.exit();
      });
    }

    /**
     * Returns the callback to be used to configure the ojRouter of this container.
     * The callback provides a way for each router state to handle their state transition (enter/exit)
     * See http://jet.us.oracle.com/jsdocs/oj.Router.html#configure
     * @return {(id:string) => Object} the "state from id" callback
     */
    getRouterConfigureCallBack() {
      return (stateId) => {
        let state;

        if (stateId) {
          state = {
            // This is the canEnter called during router transition. It is used to
            // attempt to load the page.
            // canEnter expect a Promise that resolves as true to continue or false to veto
            // the navigation.
            canEnter: () => this.loadContainer(stateId).then((nested) => !!nested),
            // canExit expect a Promise that resolves as true to continue or false to veto
            // the navigation.
            canExit: () => this.routerStateCanExitCallback(stateId),
            // A way to call the page or flow when the router enter this state.
            enter: () => this.routerStateEnterCallback(stateId),
            // A way to call the page of the flow when the router exit this state.
            // This is earlier than the ojModule handleDeactivated.
            exit: () => this.routerStateExitCallback(stateId),
            value: this,
          };
        }

        return state;
      };
    }

    /**
     * Return the first flow up in the parent hierarchy.
     * For flow, it's this.parent.parent, for page it's this.parent for
     * application it's null.
     *
     * @return {Flow} the first flow in the parent hierarchy
     */
    // eslint-disable-next-line class-methods-use-this
    getParentFlow() {
      throw undefinedMethodError('getParentFlow');
    }

    /**
     * Return true if this container should be hidden from the Url
     * Always false by default. Subclass can overwrite this behavior
     *
     * @return {boolean} true if it should be hidden from the Url
     */
    // eslint-disable-next-line class-methods-use-this
    hideFromUrl() {
      return false;
    }

    /**
     * Load the nested resource
     *
     * @param  {String} id the id of the flow
     * @param  {NavigationContext} navContext the context of the current navigation chain
     * @return {Promise<Container>} a promise that resolve to a Flow instance
     */
    // eslint-disable-next-line class-methods-use-this, no-unused-vars
    loadContainer(id, navContext) {
      throw undefinedMethodError('loadContainer');
    }

    /**
     * Retrieve the cached instance of the nested container.
     * @param  {String} id the id of the page to retrieve
     * @return {Container} the page instance
     */
    // eslint-disable-next-line class-methods-use-this, no-unused-vars
    getContainer(id) {
      throw undefinedMethodError('getContainer');
    }

    /**
     * The containership path relative to the application
     * Something like shellPageId/flowId/pageId.
     *
     * @return {String} the path
     */
    getContainerPath() {
      if (this.parent) {
        const parentId = this.parent.getContainerPath();
        return parentId ? `${parentId}${Constants.PATH_SEPARATOR}${this.id}` : this.id;
      }

      return '';
    }

    /**
     * Calculate the path to use to navigate to this page using the router.
     * This is different than getContainerPath because this one take into account the
     * hidden flow/page, so instead of the path being shellPage/flow/page, the path
     * will just be flow/page.
     *
     * @return {String}
     */
    calculateNavPath() {
      if (this.parent) {
        const parentPath = this.parent.getNavPath();
        if (this.hideFromUrl()) {
          return parentPath;
        }
        return parentPath ? `${parentPath}/${this.urlId}` : this.urlId;
      }

      return '';
    }

    /**
     * @return {String} the navigation path
     */
    getNavPath() {
      if (this.navPath === null) {
        this.navPath = this.calculateNavPath();
      }

      return this.navPath;
    }

    /**
     * Generate a unique name to be used for the scope.
     * The name is unique and has the name of the class and the path to make it easier to
     * find the scope owner when debugging.
     *
     * @return {String} a new scope name
     */
    getNewScopeName() {
      return `${this.className}/${this.fullPath}`;
    }

    loadMetadata() {
      this.loadMetadataPromise = this.loadMetadataPromise || this.descriptorLoader(this.getResourcePath())
        .then((definition) => {
          // send the descriptor to the debugger
          ApplicationDebugStream.descriptorLoaded(this.getResourcePath(), definition, this);

          // Initialize the context object, for expressions ($page OR $flow OR $chain, etc)
          this.expressionContext = new (this.constructor.ContextType)(this);

          // Initialize all the default values of the descriptor
          this.initDefault(definition);

          return this.definition;
        });

      return this.loadMetadataPromise;
    }

    /**
     * This method returns a promise to load the container definition. It uses the runtime environment
     * and the resource locator provided by each subclass the load the appropriate parts.
     * @return {Promise.<Object>} a promise that resolves when the file is loaded with the page definition object,
     * and any other dependencies (translations, imports).
     */
    loadDescriptor() {
      this.loadDescriptorPromise = this.loadDescriptorPromise || this.loadMetadata()
        // First check if we can enter the page, no point loading components or translation bundle
        // if the page cannot be accessed by the user.
        .then(() => this.checkAccess())
        // now, load anything that the JSON model requires, in parallel, and wait for all of the promises.
        // Import any JET components declared in imports.components, and resolve with the descriptor
        // and also, in parallel, load translation bundles and the extensions
        .then(() => this.loadContainerDependencies())
        // Merge the content on top of the base object
        .then(() => {
          this.combineExtensions();
          return this.definition;
        });

      return this.loadDescriptorPromise;
    }

    /**
     * This method loads anything that the JSON model requires, in parallel, and waits for all of the promises.
     * It imports any JET components declared in imports.components, and loads translation bundles and the extensions.
     *
     * @returns {Promise.<Object>} a promise that resolves when all dependencies (translations, imports...) are loaded.
     */
    loadContainerDependencies() {
      return Promise.all([this.loadExtensions(), this.loadImports(), this.loadTranslationBundles()]);
    }

    /**
     * Return a promise to load the .html for this container. Only used for page and layout.
     *
     * @returns {Promise}
     */
    loadTemplate() {
      // Keep a reference of the loading promise so that multiple function can wait
      // on the same promise to be resolved.
      this.loadHtmlPromise = this.loadHtmlPromise || this.templateLoader(this.getResourcePath());

      return this.loadHtmlPromise;
    }

    /**
     * Return the promise to load the functions module using the name of a functions loader.
     * @return {Promise.<Function|Object>}  the promise of a constructor a singleton object
     */
    loadFunctionModule() {
      if (this.loadFunctionsPromise) {
        return this.loadFunctionsPromise;
      }

      const promises = [];
      // Keep a reference of the loading promise so that multiple function can wait
      // on the same promise to be resolved.
      const loadFunctionsPromise = this.functionsLoader(this.getResourcePath())
        .then((FunctionModule) => {
          this.functions = ModelUtils.initializeJsModule(FunctionModule, this);
          return this.functions;
        })
        .catch((e) => {
          // swallow errors for missing files, log the rest
          if (!e.requireType || e.requireType !== 'scripterror') {
            this.log.error('Error loading module', this.getResourcePath(), e);
          }
          return Promise.resolve();
        });

      promises.push(loadFunctionsPromise);
      // Propagate the loadFunctionModule call to all extensions
      promises.push(...this.traverseExtensions('loadFunctionModule'));
      this.loadFunctionsPromise = Promise.all(promises);

      return this.loadFunctionsPromise;
    }

    /**
     * The class to use to create layout mode.
     * appPackage has a different one
     *
     * @type {String}
     */
    // eslint-disable-next-line class-methods-use-this
    get layoutModelClass() {
      return 'vb/private/stateManagement/layout';
    }

    /**
     * For app, flows, etc, the name of the chain folder is chains.
     *
     * @type {String}
     */
    // eslint-disable-next-line class-methods-use-this
    get chainsFolderName() {
      return 'chains';
    }

    /**
     * Returns the name of the function used to load the chain file.
     *
     * @type {String}
     */
    // eslint-disable-next-line class-methods-use-this
    get chainLoaderName() {
      return 'getTextResource';
    }

    // eslint-disable-next-line class-methods-use-this
    get chainModuleLoaderName() {
      return 'getModuleResource';
    }

    /**
     * Preload chains referenced by vbNotification and vbResourceChanged event listeners.
     *
     * NOTE: Since preloadChains calls loadChain which assumes this.chains is already initialized
     * by initializeActionChains, this method should only be called after initializeActionChains
     * has been called on the container.
     *
     * @returns {Promise}
     */
    preloadChains() {
      return Promise.resolve().then(() => {
        const { eventListeners } = this.definition;
        const promises = [];

        Object.keys(eventListeners).forEach((eventType) => {
          if (eventType === Constants.RESOURCE_CHANGED_EVENT
            || eventType === Constants.NOTIFICATION_EVENT) {
            const eventListener = eventListeners[eventType];
            const { chains } = eventListener;

            if (chains) {
              chains.forEach((chain) => {
                // to keep things simple and conservative, we will not load chains that are
                // scoped, e.g., application:myChain and page:myChain
                const chainId = chain.chain || chain.chainId;

                if (chainId.indexOf(':') === -1) {
                  promises.push(this.loadChain(chainId, !!chain.chain, true));
                }
              });
            }
          }
        });

        return Promise.allSettled(promises);
      });
    }

    /**
     * Dynamically load a file-based action chain identified by the given chainId.
     *
     * @param {String} chainId the id for the chain load
     * @param isJsChain true if this is a JS action chain
     * @param skipLoadQueue if true, skip the load queue
     * @returns {Promise}
     */
    loadChain(chainId, isJsChain, skipLoadQueue) {
      const callback = () => Promise.resolve()
        .then(() => {
          // If the chain is already defined in the container descriptor, it will take precedence over
          // file-based chain.
          const chain = this.chains[chainId];
          if (chain !== undefined) {
            return chain;
          }

          const extension = isJsChain ? '' : '.json';
          const chainLoaderName = isJsChain ? this.chainModuleLoaderName : this.chainLoaderName;
          const chainPath = `${this.getResourceFolder()}${this.chainsFolderName}/${chainId}${extension}`;

          return this.application.runtimeEnvironment[chainLoaderName](chainPath)
            .then((source) => {
              this.chains[chainId] = isJsChain ? source : Utils.parseJsonResource(source);

              // need to resend the descriptor to the debugger to update the newly loaded chain
              ApplicationDebugStream.descriptorLoaded(this.getResourcePath(), this.definition, this);

              return this.chains[chainId];
            })
            .catch((err) => {
              logger.error('Failed to load action chain', chainId, err);

              // set the chain to null so we don't try to load it again.
              this.chains[chainId] = null;

              return null;
            });
        });

      return skipLoadQueue ? callback() : this.queueLoadChain(callback);
    }

    /**
     * Queue the loading of action chains to work around issues that may arise from lazy
     * loading of file-based action chains.
     *
     * @param callback callback for loading the action chain
     * @returns {Promise}
     */
    queueLoadChain(callback) {
      this.loadChainPromise = this.loadChainPromise || Promise.resolve();

      // chain the callback to load the action chain
      this.loadChainPromise = this.loadChainPromise.then(callback);

      return this.loadChainPromise;
    }

    /**
     * Merge the content of the extension to the base object.
     * This is done on the definition of the base object before the constants are created.
     * @param extendedConstants
     * @param extension
     */
    combineExtensionConstants(extendedConstants, extension) {
      // This is the list of constants that are allowed to be modified by extensions
      const { constants } = this.definition.interface;

      // Traverse all extended constants of this extension and apply the new value to the
      // base constants definition.
      Object.keys(extendedConstants)
        .forEach((constName) => {
          const constant = constants[constName];
          if (constant) {
            const extendedConstant = extendedConstants[constName];

            // Override the value of the constant with the value from the extension if it's present
            if (typeof extendedConstant === 'object') {
              if (extendedConstant.defaultValue !== undefined) {
                constant.defaultValue = extendedConstant.defaultValue;
              } else {
                // no default value is specified in the extension, so skip it
                return;
              }
            } else {
              constant.defaultValue = extendedConstant;
            }

            // Add a reference to the extension extending the constant, this will be
            // used to retrieve in order to evaluate the expression
            constant.extension = extension;
          } else {
            this.log.warn('Constant', constName, 'is not part of the interface of', this.className, this.name);
          }
        });
    }

    /**
     * Merge the content of all extensions in the base.
     * At the moment only merging constants.
     */
    combineExtensions() {
      // Traverse all the extensions
      this.extensionsArray.forEach((extension) => {
        const extensionDef = extension.definition;
        if (extensionDef) {
          // This points to the extensions definition, extensions is already initialized in initDefault()
          const extendedConstants = extensionDef.extensions.constants;
          if (extendedConstants) {
            this.combineExtensionConstants(extendedConstants, extension);
          }
        }
      });
    }

    /**
     * Invoke the function func on each of the extensions
     * @param  {string} func the name of the function to invoke
     * @param {*} args optional arguments, passed directly to the function.
     * @return {Array}       an array where each element is the result of the function called
     */
    traverseExtensions(func, ...args) {
      const results = [];
      this.extensionsArray.forEach((extension) => {
        results.push(extension[func](...args));
      });

      return results;
    }

    /**
     * The export property is an object with the variables, constants and events that are
     * exported by this container. It corresponds to the object declared in the interface.
     * section of the json file.
     * See baseContext.js for usage.
     * @type {Object}
     */
    get export() {
      if (!this.exported) {
        this.exported = {
          enums: {},
          constants: {},
          variables: {},
        };

        // enums need to be populated first, so that contstant's values are properly evaluated next
        // when values reference $base.enums
        const { types } = this.definition.interface;

        if (types) {
          Object.keys(types).forEach((name) => {
            const enumDef = this.enums[name];
            if (enumDef) {
              this.exported.enums[name] = enumDef;
            }
          });
          // Protects export.enums from being modified. Enums cannot be added, removed or modified.
          Object.freeze(this.exported.enums);
        }

        const { constants } = this.definition.interface;

        if (constants) {
          Object.keys(constants).forEach((name) => {
            this.exported.constants[name] = this.scope.variableNamespaces[Constants.VariableNamespace.CONSTANTS][name];
          });
          // Protects export.constants from being modified. Constants cannot be added, removed or modified.
          Object.freeze(this.exported.constants);
        }

        // Only add getter to variables defined in the interface section
        const variableDefs = this.definition.interface.variables;
        if (variableDefs) {
          const vars = this.scope.variableNamespaces[Constants.VariableNamespace.VARIABLES];
          Object.keys(variableDefs).forEach((name) => {
            const descriptor = {
              get() {
                return vars[name];
              },
              enumerable: true,
            };

            // If the variable is writable, defines a setter. The absence of setter makes it read-only.
            if (variableDefs[name].mode === 'readWrite') {
              descriptor.set = (value) => {
                vars[name] = value;
              };
            }

            Object.defineProperty(this.exported.variables, name, descriptor);
          });
          // Protects export.variables from being modified. Variables cannot be added, removed or modified.
          Object.preventExtensions(this.exported.variables);
        }
      }

      return this.exported;
    }

    /**
     * Loads all the extensions that apply to this container. It resolves once the extensions are loaded
     * and stored in the base container.
     * @return {Promise} a promise resolving when each extension for this container is loaded and stored
     */
    loadExtensions() {
      return Promise.resolve()
        // TODO: simplify, only one param?
        .then(() => this.application.extensionRegistry.loadContainerExtensions(this.path, this))
        .then((exts) => this.storeExtensions(exts));
    }

    /**
     * storeExtensions
     */
    storeExtensions(extensionsArray) {
      this.extensionsArray = this.extensionsArray.concat(extensionsArray);

      extensionsArray.forEach((extension) => {
        this.extensions[extension.extensionId] = extension;
      });
    }

    /**
     * Initialize the descriptor object default value
     */
    initDefault(definition) {
      const def = definition;

      def.types = def.types || {};
      def.variables = def.variables || {};
      def.metadata = def.metadata || {};
      def.constants = def.constants || {};
      def.events = def.events || {};
      def.eventListeners = def.eventListeners || {};
      def.interface = def.interface || {};
      def.interface.events = def.interface.events || {};
      def.interface.constants = def.interface.constants || {};
      def.interface.variables = def.interface.variables || {};
      def.interface.types = def.interface.types || {};

      // Initialize the security entry with non-empty values
      def.security = def.security || {};
      const { security } = def;
      if (!security.access) {
        security.access = {};
      }

      // filter decorators
      Utils.removeDecorators(def.constants);
      Utils.removeDecorators(def.interface.constants);
      Utils.removeDecorators(def.variables);
      Utils.removeDecorators(def.interface.variables);
      Utils.removeDecorators(def.eventListeners);
      Utils.removeDecorators(def.types);
      Utils.removeDecorators(def.interface.types);
      Utils.removeDecorators(def.chains);

      // Make definition a readonly properties for safety
      Object.defineProperty(this, 'definition', {
        value: def,
        configurable: true,
        enumerable: true,
      });
    }

    /**
     * Save the parameters to the runtimeEnvironment
     * This is used by the DT to pass parameters between pages shown in different iframes
     * @param  {Object} params
     */
    saveNavigateParamsInDt(params) {
      if (params) {
        this.application.runtimeEnvironment.saveInputParameters({
          get plainParams() {
            // make sure we clone the parameters to remove getters and setters so it's a plain object
            return Utils.cloneObject(params);
          },
        });
      }
    }

    /**
     * Prepares the arguments and calls canNavigateToPage. This is only needed for DT to
     * be able cancel navigation.
     * @param  {Object} options the object with all the navigateToPage option
     * @return {Promise<Boolean>} a promise resolving to true when navigation is allowed
     */
    validateNavigationInDt(options) {
      const rtEnv = this.application.runtimeEnvironment;
      // For DT environment, retrieve current and destination page then invoke the
      // canNavigatePage to give a chance to DT to cancel navigation.
      const currentPath = this.application.getCurrentPagePath();

      if (options.operation === Constants.NavigateOperation.PAGE
        || options.operation === Constants.NavigateOperation.PAGE_OLD) {
        let destinationPath = options.page || '';

        // Build the destination full path
        if (destinationPath[0] !== '/' && this.parent) {
          const parentPath = this.parent.getContainerPath();
          if (parentPath) {
            destinationPath = `${parentPath}/${destinationPath}`;
          }
        }

        this.saveNavigateParamsInDt(options.params);
        return rtEnv.canNavigateToPage(currentPath, destinationPath);
      }

      this.saveNavigateParamsInDt(options.params);
      return rtEnv.canNavigate(currentPath, options);
    }

    /**
     * returns an error message when the options are incorrect.
     * @param options
     */
    validateNavigation(options) {
      // When navigating to an other App UI, verify the destination is marked as navigable
      if (options.operation === Constants.NavigateOperation.APP_UI
        && options.application !== '' && options.application !== (this.package && this.package.id)) {
        this.application.appUiInfos.validateNavigation(options);
      }
    }

    /**
     * Navigate to a page of the application with given input parameters.
     *
     * @param {Object} options         The navigation options
     * @param {String} options.operation The navigation operation to perform
     * @param {String} options.application The id of the destination App UI
     * @param {String} options.page    The destination page path relative to this flow (required)
     * @param {String} options.flow    The id of the destination flow.
     * @param {String} options.target  An option destination when using the flow property
     * @param {Object} options.params  A map of URL parameters (optional)
     * @param {String} options.history Effect on the browser history. Allowed value are 'replace', 'skip' or 'push'.
     *                                 If the value is 'replace', the current browser history entry is replace,
     *                                 meaning that back button will not go back to it.
     *                                 If the value is 'skip', the URL is left untouched.
     *                                 (optional and default is push)
     * @param {Object} navContext      the navigation context (See NavigationContext)
     * @returns {Promise}              a promise that resolves to an object with a property named 'navigated' which
     * value is true or false depending if the navigation reaches the target page
     */
    navigateOperation(options, navContext) {
      return Promise.resolve().then(() => {
        let getLeafPageInstancePromise;
        let destination;
        let message = '';
        let target;

        switch (options.operation) {
          case Constants.NavigateOperation.PAGE:
            destination = options.page;
            message = 'page';
            getLeafPageInstancePromise = this.getLeafPageInstance(destination, navContext);
            break;

          case Constants.NavigateOperation.FLOW:
            destination = options.flow;
            message = 'flow';
            // Changing the parent flow consist of change the flow of the parent page
            target = options.target === 'parent' ? this.parent.parent : this;
            getLeafPageInstancePromise = target.getLeafPageInstanceFromFlowId(destination, navContext);
            break;

          case Constants.NavigateOperation.APP_UI:
            {
              // When the application parameter is '', it navigates to the current App UI.
              const appUiId = options.application || this.package.id;
              // Always navigate to the urlId
              destination = this.application.appUiInfos.getUrlIdFromAppId(appUiId);
              message = 'App UI';
              // Navigation to an app-flow is always done by application
              getLeafPageInstancePromise = this.application.prepareHostRootPage(options.application)
                .then(() => this.application.getLeafPageInstanceFromAppUiId(destination, navContext));
            }
            break;

          case Constants.NavigateOperation.PAGE_OLD:
            destination = options.page;
            message = 'path';
            getLeafPageInstancePromise = this.getLeafPageInstance(destination, navContext);
            break;

          default:
            break;
        }

        this.log.info('Starting navigation to', message, destination);

        const oldInputParams = Utils.cloneObject(History.getInputParameters());
        const newInputParams = options.params || {};

        // diffInputParam is defined if the input parameters are different from what's on the browser history.
        const diffInputParam = Utils.diff(newInputParams, oldInputParams);
        if (diffInputParam) {
          // Store a copy of the new input parameters to the history state
          History.setInputParameters(newInputParams);
        }

        return getLeafPageInstancePromise.then((page) => {
          // Page can be undefined when navigation is cancelled
          if (page) {
            // If the destination is in an other App UI, check if the page is navigable
            if (navContext.container.package !== page.package) {
              // The following function throws if the page is not navigable
              page.checkNavigable();
            }
            return this.navigate(page, options, diffInputParam, oldInputParams);
          }

          // Clean up any created page during the cancelled/aborted navigation
          navContext.clean();

          return { navigated: false };
        });
      });
    }

    /**
     * Given a path, returns a promise that resolves with the page instance nested the deepest.
     * The page path can be a page id or a path of ids starting with a page id like:
     * pageId/flowId/pageId...
     * When done traversing the given path, continue drilling in the default page
     * or default container up to the point where the leaf page is reached which is
     * a page without a default flow.
     *
     * Called by navigateOperation
     *
     * @param  {String} pagePath   the navigation path
     * @param  {Object} navContext the navigation context
     * @returns {Promise}  a Promise resolving to the leaf page instance
     */
    getLeafPageInstance(pagePath, navContext) {
      // If the path starts with /, starts the navigation relative to
      // the default page of the application
      if (pagePath[0] === '/') {
        return this.application.getLeafPageInstance(pagePath.substr(1), navContext);
      }

      if (pagePath === '') {
        // With the navigate action, an empty path means navigate to the default page of the parent flow
        return this.parent.loadDefaultContainers(navContext);
      }

      // 3 phases:
      //   * Load the container for the first segment of the path
      //   * Load any other segment in the path
      //   * Load default containers

      const segments = pagePath.split('/');

      // Load the first segment
      return this.loadFirstPathSegment(segments.shift(), navContext)
        .then((nestedContainer) => {
          // It is possible firstSegment return undefined when beforeEnter return false or
          // when the page is not found.
          // In that case, the navigation is cancelled so no point drilling in nested page,
          // just return undefined.
          if (!nestedContainer) {
            return Promise.resolve();
          }

          // Loads other segments in the path
          return nestedContainer.loadPathSegments(segments, navContext);
        })
        .then((nestedContainer) => {
          if (!nestedContainer) {
            return Promise.resolve();
          }

          // Now that we loaded the container of the last segment of the path,
          // drill in the default to reach the leaf page.
          return nestedContainer.loadDefaultContainers(navContext);
        })
        .catch((error) => {
          if (HttpError.isFileNotFound(error)) {
            this.fireNotificationEvent(`${Utils.formatLoadError(error)} while loading path "${pagePath}".`);
          } else {
            throw error;
          }
        });
    }

    /**
     * Fire a notification event using a message.
     * The event uses the default setting: empty summary, persist display mode and error type
     *
     * @param  {String} message the message to put in the payload of the event
     */
    fireNotificationEvent(message) {
      const eventHelper = new EventHelper({ container: this });
      return eventHelper.fireNotificationEvent({ message });
    }

    /**
     * Retrieve the leaf page instance when navigating to a flow.
     * Given a flowId and the navigation action parameters stored in navContext.options.
     * and return a promise that resolves with the page instance nested the deepest.
     * When done traversing the given path, continue drilling in the default page
     * or default container up to the point where the leaf page is reached, which is
     * a page without a default flow.
     *
     * Called by navigateOperation
     *
     * @param  {String}   flowId the id of the flow to navigate to
     * @param  {Object}   navContext including options with navigate action parameters
     * @return {Promise}  a Promise resolving to the leaf page instance
     */
    getLeafPageInstanceFromFlowId(flowId, navContext) {
      let pagePath = navContext.options.page;

      // Ignore the slash at the beginning of the page path because when navigating from a flow,
      // the page location is always relative to the flow.
      if (pagePath && pagePath[0] === '/') {
        pagePath = pagePath.substring(1);
        // eslint-disable-next-line no-param-reassign
        navContext.options.page = pagePath;
      }

      // An empty flow id without a page means navigate to the default page or the default flow
      if (!flowId && !pagePath) {
        return this.loadDefaultContainers(navContext);
      }

      // Load the flow
      return this.loadContainer(flowId, navContext)
        .then((flow) => {
          if (flow) {
            if (!pagePath) {
              return flow.loadDefaultContainers(navContext);
            }

            return flow.getLeafPageInstance(pagePath, navContext);
          }

          return undefined;
        })
        .catch((error) => {
          if (HttpError.isFileNotFound(error)) {
            this.log.error(error);
            this.fireNotificationEvent(`${Utils.formatLoadError(error)} while loading "${flowId}".`);
          } else {
            throw error;
          }
        });
    }

    /**
     * Retrieve a flow instance. If it doesn't exist, create and load it but never returns
     * undefined.
     *
     * @param  {String} id the flow id
     * @param  {NavigationContext} navContext the context of the current navigation chain
     * @return {Promise<Container>} a promise which resolve with the flow instance
     */
    loadFlowFromId(id, navContext) {
      return Promise.resolve().then(() => {
        let flow = this.flows[id];

        if (flow) {
          return flow;
        }

        flow = this.createFlow(id, this);

        return flow.load()
          .then(() => flow.processDefaultPage())
          .then(() => {
            if (!navContext) {
              // A child Router was just created in the flow so if the load was triggerred by the
              // router (which is the case navContext is not defined) then we need to call sync on
              // the JET router to synchronize the state of the routers with the URL.
              // This can happen in 2 cases:
              //   1) when the page is refreshed
              //   2) when going back or forward in the browser history.
              Router.sync();
            }

            this.flows[id] = flow;

            return flow;
          });
      });
    }

    /**
     * From the current container this method drills into the defaults to
     * get to the leaf page.
     * @return {Promise} a promise that resolves to the leaf page or undefined
     */
    loadDefaultContainers(navContext) {
      const id = this.router.defaultStateId;
      if (id) {
        return this.loadContainer(id, navContext)
          .then((nestedContainer) => (nestedContainer
            ? nestedContainer.loadDefaultContainers(navContext)
            : undefined));
      }
      // If there is no default, we reached the leaf page
      return Promise.resolve(this);
    }

    /**
     * Loads the container for the first segment of a navigation path
     * The default is to load the container directly. The Page object has a
     * different implementation
     *
     * @param  {String} id
     * @param  {Object} navContext
     * @return {Promise}
     */
    loadFirstPathSegment(id, navContext) {
      return this.loadContainer(id, navContext);
    }

    /**
     * Traverse the path segment and load the containers
     * @param  {Array} segments array of path segments
     * @param  {Object} navContext
     * @return {Promise} a promise that resolves to a container or undefined
     */
    loadPathSegments(segments, navContext) {
      if (segments.length > 0) {
        // Loads the container recurse with what is left of the array.
        return this.loadContainer(segments.shift(), navContext)
          .then((nestedContainer) => (nestedContainer
            ? nestedContainer.loadPathSegments(segments, navContext)
            : undefined));
      }

      return Promise.resolve(this);
    }

    /**
     * Given a page object, navigate to this page
     * @param  {Page} page             the page object
     * @param  {Object} options        the navigateToPage action parameters
     * @param  {Object} diffInputParam an object truthy if old an new input parameters are different
     * @param  {Object} oldInputParams an object representing the old input parameter values
     */
    navigate(page, options, diffInputParam, oldInputParams) {
      // Turn on the skip mode on the history so that URL is not modified
      History.setSkipMode(options.history === Constants.HistoryMode.SKIP);

      const navigationPath = page.getNavPath();

      // When navigation is to the same page, simply update the input parameters. We don't
      // want to call Router.go so we can detect popstate event case in the navigated event
      const navigateToSamePage = () => {
        let navigated = { navigated: true };
        let resultMsg = 'cancelled';
        let reasonMsg = 'because it is the current page and input parameters';

        // If input params are the same as what is on the browser history,
        // there is no state change so nothing to do
        if (!diffInputParam) {
          reasonMsg += ' are the same';
          navigated = { navigated: false };
        } else if (!options.params) {
          // Navigating to the same page without param is no-op, so restore the original
          // input param and bail out like nothing happen.
          History.setInputParameters(oldInputParams);
          reasonMsg += ' are undefined';
          navigated = { navigated: false };
        } else {
          // Update the each flow/page in the current hierarchy with the new parameters
          page.refreshInputParameters();
          // Push the new state to the browser history
          History.pushState();

          // There are 2 types of path for a page, one with the hidden shell page id (fullPath)
          // looking like shell/main/start and one without (getNavPath) looking like main/start.
          // Make sure to update the browser history with fullPath, not navigationPath. This is
          // because when the router handle the popState event in popStateEventListener, it
          // compares the fullPath of the current page, not the navigation path. Also note
          // that fullPath is also the value used when calling Router.updateState in page.run()
          Router.updateState(page.fullPath);
          History.sync();

          this.application.previousPagePath = navigationPath;

          this.application.currentPageParams = Utils.cloneObject(History.getInputParameters());

          page.invokeAfterNavigateEvent({ previousPageParams: oldInputParams });

          resultMsg = 'done';
          reasonMsg = '';
          Router.clearBusyState();
        }

        this.log.info('Navigation to', navigationPath, 'is', resultMsg, reasonMsg);
        return navigated;
      };

      const navigateToOtherPage = () => {
        let goOptions;
        if (options.history === Constants.HistoryMode.REPLACE || options.history === Constants.HistoryMode.SKIP) {
          goOptions = { historyUpdate: options.history };
        }

        // Before initiating the router navigation, clear up the current search because of the
        // possible fromUrl input parameter. Make a copy of the search in case the navigation does
        // not succeed.
        const oldSearch = History.getSearch();
        History.resetUrlParameters();

        // Because of default page defined using flow, the navigation path might be different
        // from original path so use the page fullPath.
        // Launch the navigation and return the outcome immediately without waiting for the
        // navigation to complete.
        Router.go(navigationPath, goOptions)
          .then((result) => {
            if (result.hasChanged === false) {
              // If the navigated was vetoed by a beforeExit event, we need to clear the busy state
              Router.clearBusyState();
              // Restore search and input params if navigation was cancelled.
              History.setSearch(oldSearch);
              History.setInputParameters(oldInputParams);
              History.setSkipMode(false);
            } else {
              // It is critical to immediately update the cached value of the URL, otherwise,
              // the wrong URL might be used when replacing the URL to add the page/flow parameter.
              History.resetUri();

              // If the window is currently scrolled, the browser behavior is to keep the scrolled
              // position. This is not desired when navigating, so scroll to the top.
              window.scroll(0, 0);
              // Remove existing hash from URL since JET router does not
              History.setHash();
              // Update the page parents with the new parameters. No need to do the page which
              // has been initialized with the correct input parameters.
              page.parent.refreshInputParameters(true);
            }
          })
          .catch((obj) => {
            page.parent.deletePage(page.id);
            // Restore search and input params if navigation failed.
            History.setSearch(oldSearch);
            History.setInputParameters(oldInputParams);
            History.setSkipMode(false);
            throw obj;
          });

        return { navigated: true };
      };

      return (this.application.getCurrentPagePath() === navigationPath)
        ? navigateToSamePage()
        : navigateToOtherPage();
    }

    /**
     * Returns an Array of objects that contain a (curried) this.callActionChain function.
     *
     * @param {Object} eventListenerDef either (new) contains an 'actions' array,
     * or (old) is an array, of objects with 'chainId'
     * @return {Array<{chainId: string, fnc: function}>}
     *
     */
    createListenerChainFunctions(eventListenerDef) {
      const functionWrappers = [];

      const listenerChains = eventListenerDef.chains || [];

      listenerChains.forEach((listenerAction) => {
        // setup the action chain and execute
        const { chainId, chain, parameters } = listenerAction;

        // curry a function that will invoke the action chain with the parameters
        // the function still requires the 'expressionContexts' argument
        const fnc = (expressionContexts) => {
          const isJsChain = !!chain;

          // For JS chains, we need to wrap the result in an object in order to be consistent with JSON
          // chains because invokeBeforeEvent assumes the latter format.
          return this.callActionChain(chain || chainId, parameters || {}, isJsChain, expressionContexts)
            .then((result) => (isJsChain ? { result } : result));
        };
        functionWrappers.push({ chainId, fnc });
      });
      return functionWrappers;
    }

    /**
     * call the functions returned from createListenerChainFunctions in parallel
     * this is the original behavior, for older non-declared events, and VB events
     *
     * @param {Array<{chainId: string, fnc: function}>} chainFunctionWrappers
     * @param {Object} expressionContexts
     * @return {Promise}
     */
    callListenerChainFunctionsInParallel(chainFunctionWrappers, expressionContexts) {
      // swallow the error here and defer to the action chains to report errors
      // todo: exception was being swallowed with no reporting;
      // reason for this change was user error, example used $page.functions in a
      // module calling callModuleFunctionAction ($page not defined).
      const promises = chainFunctionWrappers.map((wrapper) => wrapper.fnc(expressionContexts));
      return Promise.all(promises).catch((err) => {
        this.log.error(err);

        // alwaysRethrowException is set by functional test to allow checking for failure cases
        if (this.application.alwaysRethrowException) {
          throw err;
        }

        return Promise.resolve();
      });
    }

    /**
     * Call the onVariableChange event listeners.
     *
     * NOTE: this method matches the functionality of the original 'callEventListeners', which was removed,
     * and split into createListenerChainFunctions/callListenerChainFunctionsInParallel.
     *
     * The name is changed because it is now called specifically for variables, and should not be used for
     * general event processing.
     * Events can now have 'behavior', which may determine how the chains should be called (serially, parallel, etc).
     *
     * @param {Object} eventListenerDef
     * @param {Object} expressionContexts
     * @returns {Promise}
     */
    callVariableEventListeners(eventListenerDef, expressionContexts) {
      const chainFunctionWrappers = this.createListenerChainFunctions(eventListenerDef);
      return this.callListenerChainFunctionsInParallel(chainFunctionWrappers, expressionContexts);
    }

    /**
     * Invokes an action chain with the given ID. The ID may include qualifiers to invoke
     * an action chain in different contexts.
     *
     * The parameters will be passed directly into the chain and may be expressions.
     *
     * A promise will be returned containing the name of the outcome and result from calling
     * that chain. Errors occurring during the chain will result in this promise being rejected.
     *
     * @final
     * @param chainId      The id of the chain to invoke
     * @param params  The parameters to the chain
     * @param isJsChain true if this is a JS action chain
     * @param expressionContexts
     * @returns {Promise.<*>} The result of calling the action chain
     */
    callActionChain(chainId, params = {}, isJsChain, expressionContexts = this.getAvailableContexts()) {
      const context = {
        application: this.application,
        parent: this.parent,
        container: this,
        chains: this.chains,
      };

      if (expressionContexts && expressionContexts[Constants.ContextName.EVENT]) {
        const e = expressionContexts[Constants.ContextName.EVENT];
        logger.finer('action chain', chainId, 'called with params', params, 'for event', e);
      }

      return ActionChainUtils.startChain(chainId, params, context, expressionContexts, isJsChain);
    }

    /**
     * The complete set of variables {Context} for expression evaluation of variable default values during
     * variable initialization.
     *
     * This method is used by createVariable and invokeEvent in container.js.
     *
     * The container subclass should create the availableContexts object
     *
     * @return {Object} a map of expression contexts, mapped by $<page/flow/application/etc.>
     */
    getAvailableContexts() {
      if (!this.availableContexts) {
        // Call the static getAvailableContexts method on the context object (pageContext, flowContext, etc)
        // and returns the set of $xxxx variables available for binding and expressions
        this.availableContexts = this.constructor.ContextType.getAvailableContexts(this);
        // Propagate the getAvailableContexts call to all extensions
        this.traverseExtensions('getAvailableContexts');
      }
      return this.availableContexts;
    }

    /**
     * returns the ContextType constructor used to create the '$' expression context
     * @return {ContainerContext} constructor of type ContainerContext
     * @see loadMetadata
     */
    static get ContextType() {
      throw undefinedPropertyError('ContextType');
    }

    /**
     * the definition has been loaded, now create any event-related constructs
     */
    initializeEvents() {
      this.initializeEventDefinitions();
      this.initializeComponentListeners();

      // Propagate the initializeEvents call to all extensions
      this.traverseExtensions('initializeEvents');
    }

    /**
     * look at "events" and "componentEvents" and register the declarations
     */
    initializeEventDefinitions() {
      // process "events" and "componentEvents", at top level and in "interface"
      const interfaceEvents = this.definition.interface.events || {};
      Object.keys(interfaceEvents)
        .forEach((eventName) => {
          const def = interfaceEvents[eventName];
          // 'listenable' is the default for interface events
          if (!def.mode) {
            def.mode = Constants.EventMode.LISTENABLE;
          }
        });

      // Events defined in interface have precedence. There is an audit in DT to catch duplicates
      const allDefs = Object.assign({}, this.definition.events, interfaceEvents);

      Object.keys(allDefs)
        .forEach((eventName) => {
          const def = allDefs[eventName];
          const isInterface = !!interfaceEvents[eventName];
          EventRegistry.register(this, eventName, def, isInterface);
        });
    }

    /**
     *
     */
    initializeComponentListeners() {
      const listeners = this.definition.eventListeners || {};

      // Setup the component event listeners
      Object.keys(listeners).forEach((eventName) => {
        this.eventListeners[eventName] = this.createComponentEventFunction(eventName, listeners[eventName]);
      });
    }

    /**
     * creates a method for the $listeners function map.
     * Invokes the action chain listener asynchronously, and evaluates some synchronous, optional, expression
     * properties (stopPropagation, preventDefault) to control DOM event behavior.
     * The synchronous properties do not affect calling the listener action chains, they only affect the behavior of
     * the DOM event.
     * @param {String} eventListenerName
     * @param {Object} eventListener
     * @returns {function}
     */
    createComponentEventFunction(eventListenerName, eventListener) {
      return (event, current, bindingContext) => {
        // Once exit has been called on a container (most likely a page since it's a component event)
        // then the container is unable to handle the event, so just ignore it.
        if (this.lifecycleState === Constants.ContainerState.EXITED) {
          return;
        }
        // clone the event; methods won't work, but they don't work asynchronously, anyway.
        // this means 'currentTarget' will stick around
        // todo: revisit cloneObject(event)
        // when discussed, (cds) said just pass it as is, but with the previous 'ui' hack below, that seems
        // strange to modify the actual event.  also, I (mjb) would like to do a similar hack for the 'currentTarget',
        // which becomes null after the sync listener, but can be useful when sharing event listeners.
        const newEvent = Utils.cloneObject(event, {});

        // async, don't wait
        this.invokeComponentEvent(eventListenerName, newEvent, current, bindingContext);

        const expressionContexts = this.getAvailableContexts().clone();
        expressionContexts[Constants.ContextName.EVENT] = newEvent;

        // now, look at the listener, to see if it has a 'stopPropagation' expression.
        // NOTE: this expression cannot reference the current listeners result, since it is running asynchronously.
        // NOTE: the action chains have already been called at this point; these expressions do not prevent that
        const stopPropagation = this.evaluateSynchronousHandlerProperty(eventListener.stopPropagation,
          expressionContexts);

        // if 'stopPropagation' expression is true, call event.stopPropagation
        if (stopPropagation) {
          if (typeof event.stopPropagation === 'function') {
            this.log.info('stopping event propagation in', eventListenerName, 'event listener');
            event.stopPropagation();
          } else {
            this.log.warn(eventListenerName,
              'event listener has a true \'stopPropagation\' expression,',
              ' but listener argument does not have \'stopPropagation\' method');
          }
        }

        // and look at the listener, to see if it has a 'preventDefault' expression
        const preventDefault = this.evaluateSynchronousHandlerProperty(eventListener.preventDefault,
          expressionContexts);

        // likewise, if  'preventDefault' expression is true, call event.preventDefault
        if (preventDefault) {
          if (typeof event.preventDefault === 'function') {
            this.log.info('preventing default behavior for event in', eventListenerName, 'event listener');
            event.preventDefault();
          } else {
            this.log.warn(eventListenerName, 'event listener has a true \'preventDefault\' expression,',
              'but listener argument does not have \'preventDefault\' method');
          }
        }
      };
    }

    /**
     * Invoke a page event listener using its name.
     * @param {string}    eventName the name of the event listener array
     * @param {*}         eventPayload the payload of the event
     * @param {*}         current the payload (if available) of the event (i.e. the iterable of a foreach)
     * @param {*}         bindingContext binding context of the event
     * @return {Promise}  a promise that resolves when every listener in the array is done
     */
    invokeComponentEvent(eventName, eventPayload, current, bindingContext) {
      const eventListenerDef = this.definition.eventListeners[eventName];

      if (eventListenerDef) {
        // collect the variables
        const expressionContexts = this.getAvailableContexts().clone();

        // and add the $event, $current, and $eventContext properties
        expressionContexts[Constants.ContextName.EVENT] = eventPayload;
        expressionContexts[Constants.ContextName.CURRENT] = current;
        expressionContexts[Constants.ContextName.BINDING_CONTEXT] = bindingContext;

        logger.beforeHandleEvent(this.className, this.id,
          'handling component event', eventName, 'with payload:', eventPayload, 'and current:', current);

        const mo = new EventMonitorOptions(
          EventMonitorOptions.SPAN_NAMES.EVENT_COMPONENT, eventName, eventPayload, this,
        );
        return this.log.monitor(mo, (eventTime) => {
          // Set the busy context so the webdriver test know to wait on the event to be handled
          const busyContext = ojContext.getPageContext().getBusyContext();
          const busyStateResolver = busyContext.addBusyState({ description: eventName });
          const chainFunctionWrappers = this.createListenerChainFunctions(eventListenerDef);
          // callListenerChainFunctionsInParallel is the original behavior, that calls chains in parallel
          return this.callListenerChainFunctionsInParallel(chainFunctionWrappers, expressionContexts)
            .then(() => {
              logger.afterHandleEvent(this.className, this.id,
                'handled component event', eventName, 'successfully', eventTime());
            })
            .catch((error) => {
              logger.afterHandleEvent('Failed to handle component event', eventName, eventTime(error));
              this.log.error(error);
            })
            .finally(() => {
              busyStateResolver();
            });
        });
      }

      return Promise.resolve(null); // todo: what should we really use?
    }

    /**
     * for the event listener, look for an optional property of the given name, and create an
     * expression from its value.
     * If the expression evaluates to true, this method returns true.
     *
     * @param expressionString expression. Return 'defaultValue' value if null
     * @param expressionContexts normal page expression context, plus $event
     * @param defaultValue optional value to return when the expression is null or undefined. defaults to false.
     * @returns {*|boolean}
     */
    // eslint-disable-next-line class-methods-use-this
    evaluateSynchronousHandlerProperty(expressionString, expressionContexts, defaultValue = false) {
      if (expressionString) {
        const expr = StateUtils.getValueOrExpression(expressionString, expressionContexts);
        return Utils.resolveIfObservable(expr);
      }
      return defaultValue;
    }

    /**
     * Called by the router to give the opportunity to the container to cancel navigation
     * on exit. The default implementation is to return true to accept the router transition.
     * @return {Promise<boolean>} a promise that resolves to a boolean.
     */
    // eslint-disable-next-line class-methods-use-this
    canExit() {
      return Promise.resolve(true);
    }

    /**
     * Invoke an event using the current expression contexts
     *
     * @param {string} eventName the name of the event listener array
     * @param {*} eventPayload (optional) the payload of the event
     * @param {EventBehavior|*} eventBehavior (optional) only provided when the caller is EventBehavior
     * @param {*} previousResult (optional) only valid for events of type "transform"; the previous listener result
     * @return {Promise} a promise that resolves when every listener in the array is done
     */
    invokeEvent(eventName, eventPayload, eventBehavior, previousResult) {
      if (eventName.startsWith(Constants.VB_EVENT_MARKER)) {
        Performance.timestamp(this.id, eventName);
      }
      const mo = new EventMonitorOptions(
        EventMonitorOptions.SPAN_NAMES.EVENT_RUNTIME, eventName, eventPayload, this,
      );
      return this.log.monitor(mo, (finish) => Promise.resolve()
        .then(() => {
          const lifecycleEventPromises = [];

          const eventListenerDef = this.findEventListener(eventName);

          if (eventListenerDef) {
            // get the expression context variables
            const expressionContexts = this.getAvailableContexts().clone();
            // and add the $event
            expressionContexts[Constants.ContextName.EVENT] = eventPayload;

            // $parameters only meaningful for vbBeforeEnter
            // TODO: Instead of checking for the beforeEnter event, there should be
            // an event class on which we would invoke preHandling and postHandling
            // tasks. The beforeEnter event implementation would do the parameter
            // building there.
            if (eventName === Constants.BEFORE_ENTER_EVENT) {
              expressionContexts[Constants.ContextName.PARAMETERS] = this.isExtension() && this.base
                ? this.base.buildAllParameters() : this.buildAllParameters();
            }

            const chainFunctionWrappers = this.createListenerChainFunctions(eventListenerDef);
            let promise;

            if (eventBehavior) {
              promise = eventBehavior
                .callChainFunctions(this, chainFunctionWrappers, expressionContexts, previousResult);
              // because vbAfterNavigate is a bubbling event we also need to
              // traverse extensions so that their listeners are fired as well
              if (eventName !== Constants.AFTER_NAVIGATE_EVENT) {
                return promise;
              }
            } else {
              // this is used for internal ('vbXXX').
              promise = this.callListenerChainFunctionsInParallel(chainFunctionWrappers, expressionContexts);
            }

            lifecycleEventPromises.push(promise);
          }

          // VBS-1792 support vbEnter events for extensions
          // VBS-2088 support vbExit and vbAfterNavigate for extensions
          // VBS-6440 support vbBeforeEnter and vbBeforeExit for extensions
          if (eventName === Constants.ENTER_EVENT
            || eventName === Constants.EXIT_EVENT
            || eventName === Constants.AFTER_NAVIGATE_EVENT
            || eventName === Constants.BEFORE_ENTER_EVENT
            || eventName === Constants.BEFORE_EXIT_EVENT) {
            const promises = this
              .traverseExtensions('invokeEvent', eventName, eventPayload, eventBehavior, previousResult);
            lifecycleEventPromises.push(...promises);
          }

          // don't return array, keep pre VBS-1792 behavior, just in case
          return Promise.all(lifecycleEventPromises).then((results) => {
            const listenerResults = results.filter((r) => r !== Constants.NO_EVENT_LISTENER_RESPONSE);

            // if no listeners, return the marker; used when collecting (transform event) results.
            if (listenerResults.length === 0) {
              return Constants.NO_EVENT_LISTENER_RESPONSE;
            }

            if (eventName === Constants.BEFORE_ENTER_EVENT || eventName === Constants.BEFORE_EXIT_EVENT) {
              // in case of the vbBeforeEnter and vbBeforeExit events, all listener promises results
              // need to be combined so that we can check if any one of them was cancelled
              const combinedListenerResults = [];
              for (let i = 0; i < listenerResults.length; i += 1) {
                combinedListenerResults.push(...listenerResults[i]);
              }

              return combinedListenerResults;
            }

            return listenerResults[0];
          });
        })
        .then((outcome) => {
          if (eventName === Constants.ENTER_EVENT || eventName === Constants.EXIT_EVENT) {
            this.log.info(this.className, this.id, 'completed', eventName, finish());
          } else {
            finish();
          }
          return outcome;
        })
        .catch((err) => {
          if (eventName === Constants.ENTER_EVENT || eventName === Constants.EXIT_EVENT) {
            this.log.info(this.className, this.id, 'failed to complete', eventName, finish(err));
          } else {
            finish(err);
          }
          throw err;
        }));
    }

    /**
     * find a listener, if it exists, for the event name
     * @param eventName may (or may not) be qualified
     * @returns {*}
     * @private
     */
    findEventListener(eventName) {
      return EventRegistry.findListener(this, eventName);
    }

    /**
     * Return all the variable definitions, from top level and from interface
     * @return {Object}
     */
    getAllVariablesDefinition() {
      // Variables defined in interface have precedence. There is an audit in DT to catch duplicates
      return Object.assign({}, this.definition.variables, this.definition.interface.variables);
    }

    /**
     * Invoke a (page) event listener using its name.
     *
     * NOTE: this is currently implemented to allow either DECLARED or UNDECLARED events (the original events,
     * like lifecycle events, or custom and notification events).
     *
     * If the current page/flow does not have a listener, or the listener explicitly returns an outcome
     *  to indicate bubbling should continue (see 'shouldBubble'),
     * then, traverse up the containment, looking for additional listeners, as:
     *
     * a) this.parent (flows and pages can both have listeners).
     * b) continue to recurse to (a) where 'this' is the current flow 'this.parent' from (b)
     *
     * this method is called once; the recursion happens in EventBehavior.recurseContainmentAndCreateFunctions()
     *
     * @param {string} eventName
     * @param {*} eventPayload
     *
     * @returns {Promise} resolved value depends on the event behavior (if any). only used for 'transform' behavior
     */
    invokeEventWithBubbling(eventName, eventPayload) {
      return Promise.resolve()
        .then(() => {
          /**
           * start the actual recursion, looking for listeners, and optionally continuing up the containment
           */
          const expressionContexts = this.getAvailableContexts().clone();
          expressionContexts[Constants.ContextName.EVENT] = eventPayload;

          const eventModel = EventRegistry.get(this, this, eventName);

          // This is the container where we start the event propagation.
          // For declared events, we always start with the 'leaf' page in the containment chain.
          // For (legacy) undeclared custom events, we start with the container firing the event.
          // This difference is handled by the EventBehavior.

          if (!eventModel) {
            // this means the event was declared , but is not usable by us (declared in a child container).
            throw new Error(`Event registry returned null for event ${eventName}`);
          }

          // finding an eventModel without a behavior should not happen here, but check, just in case
          if (!eventModel.behavior) {
            throw new Error(`No behavior found for event ${eventName}`);
          }

          if (!eventModel.behavior.triggerableBy(this)) {
            throw new Error(`Event '${eventName}' is not triggerable from container '${this.id}'`);
          }

          return eventModel.behavior.start(this, eventName, eventPayload, expressionContexts);
        });
    }

    /**
     * Initializes action chains from metadata.
     *
     */
    initializeActionChains() {
      this.chains = this.definition.chains || {};
      // Propagate the initializeActionChains call to all extensions
      this.traverseExtensions('initializeActionChains');
    }

    /**
     * Initialize variables of a specific namespace
     * @param  {Object} variablesDef the definition of the variables loaded from the container json
     * @param  {String} namespace  the namespace, "variables", "metadata", See Constants.VariableNamespace
     * @param  {Object} descriptor an object with additional option to be applied to the variable
     * @return {Promise}           a promise that resolves when all variables are initialized
     */
    initializeVariableByNamespace(variablesDef, namespace, descriptor) {
      const promises = [];

      if (!variablesDef) {
        return Promise.resolve();
      }

      // build variable dependency graph
      // TODO: as a performance optimization this can be cached for the page so it's not recalculated every time the
      // page is visited
      const variablesDepsMap = StateUtils.buildVariablesDependenciesGraph(variablesDef, this.scopeResolver);

      // go through the variables and create each one.
      // this assumes the variable definition has been filtered by initDefault()
      Object.keys(variablesDef).forEach((variableName) => {
        const variableDef = variablesDef[variableName];
        const d = descriptor || {};
        d.dependencies = variablesDepsMap && variablesDepsMap[variableName];

        // metadata variables are not persisted, and don't come from the URL
        if (namespace === Constants.VariableNamespace.METADATA) {
          delete variableDef.input;
          delete variableDef.persisted;
          delete variableDef.rateLimit;
        }

        promises.push(this.createVariable(variableName, variableDef, namespace, d));
      });

      // return when all the variables have been created
      return Promise.all(promises);
    }

    /**
     * Create a scope to store the variables of this container
     */
    initVariableScope() {
      // For page scopes, the name used is the name of the page, not 'page' since there is a
      // point during the transition between page where both page scope exist.
      this.scope = Scope.createScope(this.getNewScopeName(), this);

      // create the scope Context, which contains facades, if it hasn't been created yet
      this.getAvailableContexts();

      // Propagate the initVariableScope call to all extensions
      this.traverseExtensions('initVariableScope');
    }

    /**
     * Initialize variables in each namespace
     * @return {Promise} a promise that resolves when all variables are initialized.
     */
    initAllVariableNamespace() {
      if (this.scope) {
        this.log.error('Scope already created');
        this.disposeScope();
      }
      this.initializeEnums();
      this.initVariableScope();

      // Order of initialization is:
      //   1) constants
      //   2) builtins
      //   3) variables
      //   4) metadata
      this.initializeConstants();
      this.initializeBuiltins();

      // Return a promise so that the router will not enter the nested page until
      // the flow enter event is done executing. This is so the flow variables are
      // initialized with their final value before the page access them.
      return this.initializeVariables()
        .then(() => this.initializeMetadata())
        .then(() => {
          this.addScopeToStore();
          this.subscribeVariablesToStore();
        })
        .then(() => this.activateVariables());
    }

    /**
     * Enums are considered types and defined as such, so go through all of the defined types, find
     * enums and initialize them.
     */
    initializeEnums() {
      // types defined in interface have precedence, there is an audit in DT to catch duplicates
      const allDefs = Object.assign({}, this.definition.types, this.definition.interface.types);

      // go through the types and look for enums
      const entries = Object.entries(allDefs);
      entries.forEach((entry) => {
        const type = entry[0];
        const typeDef = entry[1];
        if (typeDef && typeDef.enumType) {
          // found an enum, need to ensure it's of supported type
          // currently only primitive types are supported, i.e., string, boolean, number
          if (Utils.isPrimitiveType(typeDef.enumType)) {
            // check whether the enum initializers are entirely left off, i.e.:
            // values: [Up, Down, Left, Right]
            // if this is the case, we need to auto-initialize them
            if (Array.isArray(typeDef.values)) {
              // if values are specified via an array, it's using a shortcut
              if (typeDef.enumType === 'number') {
                // enum is of type number, auto-increment starting with 0 index
                let index = 0;
                this.enums[type] = Object.assign(...typeDef.values.map((key) => {
                  const obj = { [key]: index };
                  index += 1;
                  return obj;
                }));
              } else if (typeDef.enumType === 'string') {
                // enum is of type string, the values will be equivalent to its key
                this.enums[type] = Object.assign(...typeDef.values.map((key) => ({ [key]: key })));
              } else {
                // otherwise log an error
                this.log.error(`Enum ${type} is missing initializers.`,
                  'Only enumerations of type number or string can be defined with no initializers.');
              }
            } else {
              this.enums[type] = typeDef.values;
            }
          } else {
            this.log.error(`Enum ${type} is of unsupported enumeration type ${typeDef.enumType}.`,
              'Only primitive types are supported.');
          }
        }
      });

      // Propagate the initializeEnums call to all extensions
      this.traverseExtensions('initializeEnums');
    }

    addScopeToStore() {
      // this will add the scope to the redux store
      // Create a local store
      StoreManager.addScopeToStore(this.scope);
      // Extension store need to be created before subscribing variables
      this.traverseExtensions('addScopeToStore');
    }

    subscribeVariablesToStore() {
      this.scope.subscribeVariablesToStore();
    }

    /**
     * Activate all variables for the current container.
     * @return {Promise} that resolves when all variables are active
     * @private
     */
    activateVariables() {
      const availableContexts = this.getAvailableContexts();
      return Promise.resolve().then(() => this.scope.activateVariables(availableContexts)
        .then(() => this.traverseExtensions('activateVariables')));
    }

    /**
     * Initialize built-in variables.
     */
    initializeBuiltins() {
      // Create a constant for the "info" builtins. For flows, the info object has 2 properties,
      // id and description
      let varDef = this.defineInfoBuiltinVariable();
      if (varDef) {
        this.createConstant(Constants.INFO_CONTEXT, {
            type: 'object',
            defaultValue: varDef,
          },
          Constants.VariableNamespace.BUILTIN);
      }

      varDef = this.defineCurrentPageBuiltinVariable();
      if (varDef) {
        this.scope.createVariable(Constants.CURRENT_PAGE_VARIABLE, Constants.VariableNamespace.BUILTIN,
          varDef.type, varDef.defaultValue, undefined, { writable: false });
      }
    }

    // eslint-disable-next-line class-methods-use-this
    defineInfoBuiltinVariable() {
      return null;
    }

    /**
     * By default currentPage is not defined. Only flow and application have an implementation.
     * App UI don't need the builtin variable either since it's defined as an alias of application
     * in packageContext
     */
    // eslint-disable-next-line class-methods-use-this
    defineCurrentPageBuiltinVariable() {
      return null;
    }

    /**
     * Initialize constants from declaration.
     */
    initializeConstants() {
      // Constants defined in interface have precedence. There is an audit in DT to catch duplicates
      const allDefs = Object.assign({}, this.definition.constants, this.definition.interface.constants);

      // go through the constants and create each one.
      // this assumes the constant definition has been filtered by initDefault()
      Object.keys(allDefs).forEach((constantName) => {
        this.createConstant(constantName, allDefs[constantName]);
      });

      // Propagate the initializeConstants call to all extensions
      this.traverseExtensions('initializeConstants');
    }

    /**
     * Create a constant and store it in the scope
     * @param  {String}    constantName    the name of the constant
     * @param  {Object}    constantDef     the definition of the constant
     * @param  {String}    namespace       the variable namespace, either "constants" or "builtin"
     */
    createConstant(constantName, constantDef, namespace = Constants.VariableNamespace.CONSTANTS) {
      // Constants cannot be of a VB type
      if (Utils.isInstanceType(constantDef.type)) {
        throw new Error(`Type cannot be a built-in type for constant ${constantName} in`
          + `${this.className} ${this.fullPath || this.definition.id}.`);
      }

      // If the constant has been extended, the availableContexts is coming from the extension
      // so that the potential expression in defaultValue is evaluated in the context of the extension.
      const contextSource = constantDef.extension || this;

      const defaultValue = StateUtils.createNonInstanceTypeDefaultValue(constantName, constantDef,
        this.scopeResolver, contextSource.getAvailableContexts());

      // Input Value management: "fromCaller" or "fromUrl"
      const inputParameterValue = this.manageInputParameter(constantName, constantDef, defaultValue);
      if (inputParameterValue !== undefined) {
        this.log.info('Loaded', constantDef.input, 'constant', Utils.getWebStorageItemName(this.application.id,
          this.scope.name, Constants.VariableNamespace.CONSTANTS, constantName), 'value:', inputParameterValue);
      }

      const newConstant = this.scope.createConstant(constantName, namespace,
        constantDef.type, defaultValue, inputParameterValue, constantDef.persisted);

      // handle constant eventing
      // NOTE here the current container's available context is passed so that
      // the onValueChanged listener is evaluated in the proper context
      // the proper context will be automatically retrieved for any possible
      // onValueChanged listeners in the extensions
      this.addValueChangedListeners(newConstant, constantDef, this.getAvailableContexts());
    }

    /**
     * Initializes variables from declarations.
     *
     * @returns {Promise} A promise that resolves when all variables are initialized
     */
    initializeVariables() {
      const allDefs = this.getAllVariablesDefinition();
      const promises = [];
      promises.push(this.initializeVariableByNamespace(allDefs, Constants.VariableNamespace.VARIABLES));
      promises.push(...this.traverseExtensions('initializeVariables'));

      return Promise.all(promises);
    }

    /**
     * Initializes metadata declarations.
     *
     * @returns {Promise} A promise that resolves when complete
     */
    initializeMetadata() {
      const promises = [];
      promises.push(this.initializeVariableByNamespace(this.definition.metadata,
        Constants.VariableNamespace.METADATA,
        { writable: false }));
      promises.push(...this.traverseExtensions('initializeMetadata'));

      return Promise.all(promises);
    }

    /**
     * Creates a new variable with the proper default value, input handling, and persistence handling.
     *
     * @private
     * @param {String} variableName The name of the variable
     * @param {Object} variableDef The structure of the variable definition in the page model
     * @param {String} namespace optional, defaults to "variables"
     * @param {Object} descriptor optional, example: {writable: false}. overrides other values.
     *   - dependencies, a Map of dependencies (on other variables) for the current variable.
     *     See StateUtils.buildVariablesDependenciesGraph
     * @returns {Promise} A promise that resolves to a new variable
     */
    createVariable(variableName, variableDef, namespace = Constants.VariableNamespace.VARIABLES, descriptor) {
      const availableContexts = this.getAvailableContexts();
      const inputParamValue = this.getInputParameterFromCallerValue(variableName, variableDef);

      // determine the default value for this variable then create the variable. Also provide any input param that
      // was passed in that would be meaningful when creating the defaultValue.
      return StateUtils.createVariableDefaultValue(variableName, variableDef, this.scopeResolver,
        this.scope, availableContexts, namespace, inputParamValue)
        .then((defaultValue) => {
          // Input Value management: "fromCaller" or "fromUrl"
          // TODO: pavi: input parameter reconciliation does not work for defaultValue that is an instance -
          //  extendedType, factoryType, or 'any' random type today.
          //  - For instance factory type the caller should be able to pass in value for 'constructorParams'
          //  - For extended type the caller should be able to pass in value for 'defaultValue'
          //  - random type instances use default constructor, so no input parameter can be passed in!
          const inputParameterValue = this.manageInputParameter(variableName, variableDef, defaultValue);
          if (inputParameterValue !== undefined) {
            this.log.info('Loaded', variableDef.input, 'variable',
              Utils.getWebStorageItemName(this.application.id, this.scope.name, namespace, variableName),
              'value:', inputParameterValue);
          }

          // get the type
          const type = StateUtils.getType(variableName, variableDef, this.scopeResolver);

          // use 'descriptor', if provided. values in 'descriptor' override others
          const varDescriptor = this.getVariableDescriptor(variableDef, descriptor);

          // create the variable
          const newVariable = this.scope.createVariable(variableName, namespace,
            type, defaultValue, inputParameterValue, varDescriptor, variableDef);

          // handle variable eventing
          this.addValueChangedListeners(newVariable, variableDef, availableContexts);

          return newVariable;
        });
    }

    // eslint-disable-next-line class-methods-use-this
    getVariableDescriptor(variableDef, descriptor) {
      return Object.assign({}, {
        persisted: variableDef.persisted,
        input: variableDef.input,
        rateLimit: variableDef.rateLimit,
      }, descriptor);
    }

    /**
     * Add valueChanged listeners for the variable or constant if explicitly configured.
     * It will also add listeners defined in extensions.
     * Note: This is also called for internal variables setup for builtin types.
     * @see StateUtils.instantiateType
     * @param newVariable
     * @param variableDef
     * @param availableContexts
     */
    addValueChangedListeners(newVariable, variableDef, availableContexts) {
      const onValueChangedDef = variableDef.onValueChanged;

      if (onValueChangedDef) {
        this.handleValueChangedListener(newVariable, onValueChangedDef, availableContexts);
      }

      // even if there is no onValueChanged listener in the base, but the given variable/constant is defined in
      // the interface, we have to go through all extensions and look for the onValueChanged listeners there
      const { name } = newVariable;
      // Note DT doesn't allow the same name for both variable and constant, so only one can exist
      const isExtendable = !!(this.definition.interface.variables[name] || this.definition.interface.constants[name]);

      if (isExtendable) {
        // since this variable/constant is defined in the interface, we now need to check
        // whether it has been extended by any extensions
        this.traverseExtensions('addExtensionValueChangedListeners', newVariable);
      }
    }

    /**
     * Called to determine if an event behavior is supported by container. Most are except for dynamicComponent
     * behavior that is only supported on select container. See Layout and Fragment.
     * @param eventBehavior
     * @return {boolean}
     */
    // eslint-disable-next-line class-methods-use-this
    allowsDynamicComponentEventBehavior(eventBehavior) {
      return eventBehavior !== Constants.EventBehaviors.DYNAMIC_COMP;
    }

    /**
     * Whether container allows the event. undeclared events have been historically allowed for most containers
     * @param eventModel
     * @return {boolean}
     */
    // eslint-disable-next-line class-methods-use-this,no-unused-vars
    canFireEvent(eventModel) {
      return true;
    }

    /**
     * Whether the propagation behavior is supported by current container. Currently this property is only supported
     * on fragment and layout. Called from fireCustomEventAction.
     * @param type of propagation behavior being checked for. See Constants.EventPropagationBehaviors enum.
     * @param eventDef event definition
     * @return {boolean} true if behavior matches requested type
     */
    // eslint-disable-next-line class-methods-use-this,no-unused-vars
    allowsEventPropagation(type, eventDef) {
      // for now this is only supported on specific containers like fragment and layout
      return false;
    }

    /**
     * Adds the specified onValueChanged listener to the given variable or constant.
     * @param newVariable variable object representing a newly created variable
     * @param onValueChangedDef onValueChanged listener defined in the variable definition of the page model
     * @param availableContexts map of expression contexts
     */
    handleValueChangedListener(newVariable, onValueChangedDef, availableContexts) {
      if (!newVariable.onValueChanged) {
        this.log.warn(newVariable.name, 'does not have a live expression so its onValueChanged listener is ignored');
      } else {
        const varChangeTracker = {
          eventSource: newVariable.onValueChanged,
          eventListener: (e) => {
            const context = availableContexts.clone();
            context[Constants.ContextName.EVENT] = e; // capture event payload on the scope
            const eventName = e.type; // vb event type and name is the same
            logger.beforeHandleEvent(this.className, this.id,
              'handling variable event', eventName, 'with payload:', e);
            const mo = new EventMonitorOptions(EventMonitorOptions.SPAN_NAMES.EVENT_VARIABLE, eventName, e, this);
            return this.log.monitor(mo, (eventTime) => {
              this.callVariableEventListeners(onValueChangedDef, context).then(() => {
                logger.afterHandleEvent(this.className, this.id,
                  'handled variable event', eventName, 'successfully', eventTime());
              }).catch((error) => {
                logger.afterHandleEvent('Failed to handle variable event', eventName, eventTime(error));
                this.log.error(error);
              });
            });
          },
        };
        newVariable.onValueChanged.add(varChangeTracker.eventListener, this);
        this.variablesListeners.push(varChangeTracker);
      }
    }

    /**
     * Add the onValueChanged event listeners for the variable/constant in extensions.
     * @param newVariable variable for which to add the onValueChanged event listener
     */
    addExtensionValueChangedListeners(newVariable) {
      const { name } = newVariable;

      // determine whether the extensions variables/constants are defined
      const variables = this.definition.extensions.variables || {};
      const constants = this.definition.extensions.constants || {};
      if (variables || constants) {
        // now determine whether the given variable/constant has been extended by this extension container
        // and has the onValueChanged listener defined
        const extVar = variables[name] || constants[name];
        if (extVar && extVar.onValueChanged) {
          // the extended variable/constant has the onValueChanged listener defined in this extension container
          this.handleValueChangedListener(newVariable, extVar.onValueChanged, this.getAvailableContexts());
        }
      }
    }

    /**
     * if there is an imports declaration, load the (requireJS) modules.
     * This is how JET components, jetmodules and css modules are imported declaratively:
     *
     *  "imports": {
     *    "components": {
     *      "oj-button": {
     *          "path": "ojs/ojbutton"
     *      }
     *    },
     *     "modules" : {
     *       "converterutilsI18n": {
     *         "path": "ojs/ojconverterutils-i18n"
     *       }
     *     },
     *     "css" : [
     *        "/pages/resources/css/shell.css",
     *        "./resources/css/shell2.css",
     *        "https://static.oracle.com/cdn/fnd/gallery/2110.0.0/images/iconfont/ojuxIconFont.min.css"
     *    ]
     *  }
     * @returns {Promise}
     */
    loadImports() {
      return Promise.resolve().then(() => {
        const imports = this.definition.imports;
        if (imports) {
          const promises = [];

          // initialize any components (JET or CCAs)
          if (imports.components) {
            const promise = this.importModule(imports.components, false);

            if (promise) {
              promises.push(promise);
            }
          }

          // load any modules
          if (imports.modules) {
            const promise = this.importModule(imports.modules, true);

            if (promise) {
              promises.push(promise);
            }
          }

          // load any css
          if (imports.css) {
            promises.push(this.importCss(imports.css));
          }
          return Promise.all(promises);
        }

        return undefined;
      });
    }

    /**
     * initialize any translation bundles
     * overridden by application to set a flag that it is declared in the app, and not a flow/page
     * @returns {Promise}
     */
    loadTranslationBundles() {
      if (!this.loadBundlesPromise) {
        // todo : define system vars, for substitutions in path
        this.loadBundlesPromise = BundlesModel.loadBundlesModel(this.application.runtimeEnvironment, this.definition,
          this.getResourceFolder(), { initParams: this.application.initParams }, this.extension)
          .then((bundlesModel) => {
            this.bundles = bundlesModel;
            return this.bundles;
          });
      }
      return this.loadBundlesPromise;
    }

    /**
     * Returns the caller provided value for the input params.
     * @param {String} variableName
     * @param {Object} variableDef
     * @return {*}
     */
    getInputParameterFromCallerValue(variableName, variableDef) {
      // first load the value from runtimeEnvironment which takes precedence over everything else
      // NOTE: This is used by the DT to pass parameters between pages shown in different iframes
      const inputParams = this.application.runtimeEnvironment.loadInputParameters();
      return inputParams && inputParams[variableName];
    }

    getInputParameterValue(variableName, variableDef) {
      let paramValue;
      let inputParams;
      let fromCallerValue = this.getInputParameterFromCallerValue(variableName, variableDef);

      if (fromCallerValue === undefined) {
        inputParams = History.getInputParameters();
        fromCallerValue = inputParams && inputParams[variableName];
      }

      // If the page variable is 'fromCaller' return the input param
      if (variableDef.input === 'fromCaller') {
        paramValue = fromCallerValue;
        // If the page variable is 'fromUrl' and a URL parameter with this name exist,
        // replace the variable default value with input parameter value.
      } else if (variableDef.input === 'fromUrl') {
        // 'fromCaller' has precedence over 'fromUrl'
        if (fromCallerValue !== undefined) {
          paramValue = fromCallerValue;
        } else {
          const fromUrlValue = History.getUrlParameter(variableName);
          if (fromUrlValue !== undefined) {
            paramValue = fromUrlValue;
          }
        }
      }

      return paramValue;
    }

    /**
     * Retrieve the input parameter value for a variable of type 'fromCaller' or 'fromUrl'.
     * This method does two things:
     *   1) return the variable value if one can be found using input parameter or URL.
     *   2) for variable of input type fromUrl, save the possible value on the URL.
     * For a variable with input fromUrl, the fromCaller value has precedence.
     *
     * @param  {string}    variableName name of the variable
     * @param  {object}    variableDef  definition of the variable from descriptor
     * @param  {object|function} defaultValue the default value
     * @return {object}    the input parameter value for this variable or undefined
     */
    manageInputParameter(variableName, variableDef, defaultValue) {
      let paramValue = this.getInputParameterValue(variableName, variableDef);

      // Append fromUrl variable to the URL only if different from defaultValue
      if (variableDef.input === 'fromUrl') {
        // paramValue might need to be coerce to the right type because the value
        // is a string if it's coming from the URL
        paramValue = AssignmentHelper.coerceType(paramValue, variableDef.type);

        const defValue = Utils.resolveIfObservable(defaultValue);
        if (paramValue !== defValue) {
          History.setUrlParameter(variableName, paramValue);
        }
      }

      return paramValue;
    }

    /**
     * Refresh the container and its parent with new input parameters.
     *
     * @param  {boolean} noReset a flag indicating if variable should not be updated to its default
     * value when the input parameter is not specified.
     */
    refreshInputParameters(noReset) {
      if (this.parent) {
        this.parent.refreshInputParameters(noReset);
      }
      if (this.scope) {
        this.scope.variablesDef.forEach((variable) => {
          if (variable.namespace === Constants.VariableNamespace.VARIABLES) {
            const input = variable.descriptor && variable.descriptor.input;
            if (input === 'fromCaller' || input === 'fromUrl') {
              const allDefs = this.getAllVariablesDefinition();
              const variableDef = allDefs[variable.name];
              let value = this.manageInputParameter(variable.name, variableDef, variable.defaultValue);

              // When the noReset flag is on, only update the variable if the value is defined.
              // This is so flow input vars are not updated when navigating to an other page
              // without specifying them.
              if (noReset) {
                if (value !== undefined) {
                  variable.setValue(value);
                }
              } else {
                // If the input parameter is not defined, set the value to the default
                if (value === undefined) {
                  value = variable.defaultValue;
                }
                variable.setValue(value);
              }
            }
          }
        });
      }
    }

    /**
     * Build the title that will be used for this page.
     * Walk up the flow hierarchy and gather all the title
     *
     * @param {String} title the base of the title
     * @return {String} the title
     */
    // eslint-disable-next-line no-unused-vars,class-methods-use-this
    buildTitle(title) {
      // No-op, for subclass to implement
      return '';
    }

    /**
     * true if the types defines the type
     * @param name
     * @return {*}
     */
    hasType(name) {
      // types defined in interface have precedence. There is an audit in DT to catch duplicates
      return this.definition.interface.types[name] || this.definition.types[name];
    }

    /**
     * true if the given event is defined in the container
     * @param name
     * @returns {*}
     */
    hasEvent(name) {
      // events defined in interface have precedence. There is an audit in DT to catch duplicates
      return this.definition.interface.events[name] || this.definition.events[name];
    }

    /**
     * true if the type is defined in the interface section of the current container.
     * @param name type name
     * @returns {*}
     */
    isInterfaceType(name) {
      return this.definition.interface.types[name];
    }

    /**
     * Returns type information for the given type. This will only return information on
     * structure types, collections, or special types (i.e. ServiceDataProvider/ArrayDataProvider).
     *
     * For these, the structure of the type is defined within the 'structure' property, which
     * itself could also contain types.
     *
     * Since this method is implemented in the container base class, 'types' can be defined in
     * application, flow or page.
     *
     * @param {String} name The name of the types
     * @return {Object} the type definition for a give 'type' in 'types' meta-data
     */
    getType(name) {
      const typeDef = this.hasType(name);

      if (!typeDef) {
        throw new TypeError(`Type "${name}" does not exist in `
          + `${this.className} ${this.fullPath || this.definition.id}`);
      }

      // legacy type definition
      // Note: If you have a legacy type definition as follow:
      // types: {
      //   foo: {
      //     type: "string"
      //   }
      // }
      // we will favor the new syntax over the legacy syntax so in the above example,
      // type foo will be resolved to an object with a string property called type.
      // We believe it is very unlikely to have a legacy type definition that is simply an
      // alias for a primitive type.
      if ((typeDef.type === 'object' || typeDef.type === 'array')
        && (typeDef.definition === '*' || Utils.isObject(typeDef.definition))) {
        return typeDef;
      }

      // make it consistent with type definition on a variable
      return {
        type: typeDef,
      };
    }

    /**
     * @param prefix
     * @param variableDefinitions
     * @returns {Array}
     */
    // eslint-disable-next-line class-methods-use-this
    getTypeReferencesByPrefix(prefix = '', variableDefinitions) {
      // pure function
      function recurse(obj, callback) {
        if (obj && typeof callback === 'function') {
          Object.keys(obj).forEach((key) => {
            if (typeof obj[key] === 'string') {
              callback(key, obj[key]);
            } else if (typeof obj[key] === 'object') {
              recurse(obj[key], callback);
            }
          });
        }
      }

      const types = [];
      function getTypeNames(key, value) {
        if ((key === 'type' || key === 'definition') && value.startsWith(prefix)) {
          types.push(value.substring(prefix.length));
        }
      }

      recurse(variableDefinitions, getTypeNames);

      return types;
    }

    /**
     * Give a chance to the Security Provider to handle the error in order to possibly
     * redirect to a login page.
     * An error is thrown if the error is handled.
     *
     * @param  {Error} error the error to handle
     */
    callSecurityProvider(error) {
      const secProv = this.application.securityProvider;

      // Let the security provider handle the error. If it does there will be a
      // redirect, so re-throw the error so that the page is properly disposed,
      // otherwise swallow the exception.
      if (secProv && secProv.handleLoadError && secProv.handleLoadError(error, this.fullPath)) {
        throw error;
      }
    }

    /**
     * Walk the hierarchy of container until one has requiresAuthentication defined and returns
     * its value. The application default value is false.
     *
     * @return {Boolean} true if the authentication is required for this artifact, false otherwise
     */
    isAuthenticationRequired() {
      // security.access is initialized to non-null value in initDefault.
      const { requiresAuthentication } = this.definition.security.access;

      // if requiresAuthentication is defined, it takes precedence over the parent value
      if (requiresAuthentication !== undefined) {
        return requiresAuthentication;
      }

      // Default to true if not defined in app-flow.json (case when this.parent is falsy)
      return this.parent ? this.parent.isAuthenticationRequired() : true;
    }

    /**
     * Check if the access is allowed to this page or flow by calling the Security provider
     * Throw a 403 HttpError is the artifact require authentication and the user roles or
     * permissions don't satisfy the page security settings.
     */
    checkAccess() {
      return Promise.resolve().then(() => {
        // bypass security check when running in action chain test mode
        const vbConfig = window.vbInitConfig || {};
        if (vbConfig.TEST_MODE === Constants.TestMode.ACTION_CHAIN) {
          return;
        }

        // security.access is initialized to non-null value in initDefault.
        const accessInfo = this.definition.security.access;

        accessInfo.roles = accessInfo.roles || [];
        accessInfo.permissions = accessInfo.permissions || [];

        // Walk up the parent hierarchy to retrieve the requiresAuthentication flag
        accessInfo.requiresAuthentication = this.isAuthenticationRequired();

        if (accessInfo.requiresAuthentication === false) {
          // This is the case where the security definition is inconsistent, the
          // authentication is not required but a role or a permission is defined.
          if (accessInfo.roles.length > 0 || accessInfo.permissions.length > 0) {
            throw new Error(`Incorrect security configuration for ${this.className} ${this.fullPath}`);
          }

          // Authentication is not required, nothing else to do
          return;
        }

        const secProv = this.application.securityProvider;

        // This is the case where authentication is required but the Security Provider
        // is missing.
        if (!secProv) {
          throw new Error('A Security Provider is required to enforce access restriction'
            + ` for ${this.className} ${this.fullPath || this.definition.id}.`);
        }

        // For artifact level security, ask the security provider if the artifact access
        // info match this user. If not, throw a 403
        if (!secProv.isAccessAllowed(this.className, this.fullPath, accessInfo)) {
          // Throw a HTTP error with the 403 status "Forbidden" that will handled by the
          // Security Provider.
          throw new HttpError(403, null, `${this.fullPath} HTTP status: 403 Forbidden`);
        }
      });
    }

    /**
     * Check if there is any restriction to navigation to this container
     * By default, there isn't. See PackagePage class for a checkNavigable that
     * can throws an error
     */
    // eslint-disable-next-line class-methods-use-this
    checkNavigable() {
      // no-op
    }

    /**
     * Register a layout.
     * This is to keep track of layout object used in this container so we can clean them on dispose
     * See usage in Layout.js constructor
     * @param {Layout} layout
     */
    registerLayout(layout) {
      this.layouts.push(layout);
    }

    /**
     * Dispose the scope and variable listeners. This can be called directly by the RuntimeManager at DT before
     * refreshing a page instance.
     */
    disposeScope() {
      // Dispose need to called be removeScopeFromStore so that no state
      // listener get invoked when removing from store.
      if (this.scope) {
        this.scope.dispose();

        StoreManager.removeScopeFromStore(this.scope);
        this.scope = null;
      }

      // clean up variables listeners
      this.variablesListeners.forEach((tracker) => {
        tracker.eventSource.remove(tracker.eventListener);
      });
      this.variablesListeners = [];
    }

    dispose() {
      this.loadMetadataPromise = null;
      this.loadDescriptorPromise = null;

      // Dispose of layouts in this container
      this.layouts.forEach((layout) => layout.dispose());
      this.layouts = [];

      // Propagate the dispose call to all extensions
      this.traverseExtensions('dispose');

      this.disposeScope();
    }

    /**
     * utility method to load a css file from url, absolute path or container relative path
     *
     * @param {Array} paths the array of paths in the definition
     * @return {Promise}
     * @private
     */
    importCss(paths) {
      return Promise.resolve()
        .then(() => {
          if (!Array.isArray(paths) || paths.length === 0) {
            return undefined;
          }
          // package and extension have their onw implementation of resolveCssPaths
          const allPaths = this.resolveCssPaths(paths);

          const loadPromises = [];
          if (allPaths.urlPaths.length > 0) {
            // Complete URL are loaded directly
            loadPromises.push(Utils.getResources(allPaths.urlPaths));
          }

          if (allPaths.cssPaths.length > 0) {
            // local css needs their relative reference to be adjusted
            loadPromises.push(Container.injectCss(allPaths.cssPaths, allPaths.cssUrls));
          }

          return Promise.all(loadPromises);
        })
        .catch((reason) => {
          logger.error('Unable to load css:', reason);
          // swallow errors, just report them
        });
    }

    /**
     * Traverse the list of path from the importsCss section of the metadata and build
     * and object with 2 arrays. One array of full URL to CSS and one array of relative CSS.
     * @param  {Array<String>} paths
     * @return {Object}        Object with 3 arrays cssPaths, cssUrls and urlPaths
     */
    resolveCssPaths(paths = []) {
      const results = {
        cssPaths: [], // path to load the potentially bundled css using require
        cssUrls: [], // complete url with protocol to the location of the css
        urlPaths: [], // complete URL with protocol, nothing is done to the css
      };

      paths.forEach((path) => {
        let cssPath = path.trim();
        const uri = URI.parse(cssPath);

        // local url
        if (!uri.protocol) {
          // absolute url
          if (cssPath[0] === '/') {
            cssPath = cssPath.substring(1);
          } else { // relative path
            cssPath = `${this.getResourceFolder()}${cssPath}`;
          }

          // Use require to retrieve the full URL, so relative path become full path with
          // application base URL and path for extension (starting with vx/extId/...) get
          // resolve to the full URL on CDN
          // From: vx/extId/ui/self/applications/appUi1/resources/css/app.css
          // to:   https://some-path/.../extId/ui/self/applications/appUi1//resources/css/app.css
          const newCssPath = requirejs.toUrl(cssPath);

          // Remove the file name from the path and store it in an array for later use
          results.cssUrls.push(newCssPath.substring(0, newCssPath.lastIndexOf('/') + 1));

          // Using text! instead of css! to load the css using the text plugins so we can modify the content
          // When using require css plugin, require inject the content in the DOM instead of retrieving the content
          results.cssPaths.push(`text!${cssPath}`);
        } else {
          // Loads the url path using the css plugins since relative paths inside the css
          // are relative to the css location.
          results.urlPaths.push(`css!${cssPath}`);
        }
      });

      return results;
    }

    /**
     * Given a set of path to css files, load each css as a text file and inject
     * the combine content in the DOM using a <style> tags.
     * This routine also replace each relative paths in the css with an absolute path.
     * @param  {Array<String>} cssPaths array of paths to css
     * @param  {Array<String>} cssUrls array of URL to css location
     * @return {Promise}       a Promise that resolve when all css are loaded
     */
    static injectCss(cssPaths, cssUrls = []) {
      return Utils.getResources(cssPaths)
        // Aggregate content of all css to import, fix the path and inject the content in DOM
        .then((results) => {
          // Build one css with the content of each css entry in the import
          let allCssContent = '';

          // Traverse all the CSS entry and replace each references (url and @imports)
          results.forEach((css, index) => {
            if (css) {
              allCssContent += Utils.replacePathInCSS(css, (path) => {
                let newPath = path;

                const uri = URI.parse(path);
                // only replace the path when not a full URL
                if (!uri.protocol) {
                  // Combine the css folder calculated earlier with the path the resource
                  // in the css to build the absolute path
                  newPath = URI(`${cssUrls[index]}${path}`).normalizePath().toString();
                }
                return newPath;
              });
            }
          });

          // then inject the css in the head section of index.html
          Utils.injectStyleTag(allCssContent);
        });
    }

    /**
     * used by event propagation; when trying to start the event, we typically need to know the 'lowest'
     * container in the 'tree' (a.k.a. leaf); we start from there, and bubble up.
     * But this may be different depending on the container type; for example, Layout doesn't participate the same way.
     *
     * Usually a Page, but can be a Flow if we're processing vb system events before the Page is created.
     *
     * @returns {Container}
     */
    // eslint-disable-next-line class-methods-use-this
    getLeafContainer() {
      return Router.getCurrentPage();
    }

    /**
     * Used by import routines to recognize an import for a JET component.
     * @param  {string}  path the path entry of the component import statement
     * @return {Boolean}      true if its a JET component
     */
    static isJetComponent(path) {
      return path.startsWith('ojs/');
    }

    /**
     * Use by extension container and App UI container to adjust the path of a local resource.
     * @param  {String} path the import path
     * @return {String}      the adjusted path
     */
    // eslint-disable-next-line class-methods-use-this
    adjustImportPath(path) {
      // There is nothing to adjust to the path for a base container
      return path;
    }

    /**
     * Utility method to load a module, and log errors
     *
     * @param {Object} components
     * @param {Boolean} storeImport true when the module should be stored in this.imports
     * @return {Promise|undefined} a promise to load module if there are module to import
     * @private
     */
    importModule(components, storeImport) {
      const allPaths = [];
      const keys = [];
      let promise;

      Object.keys(components).forEach((key) => {
        const importDef = components[key];
        let path;

        if (Utils.isObject(importDef)) {
          path = importDef.path;
          path = (typeof path === 'string') && path.trim();
        }

        if (!path) {
          this.log.error('Unable to load component', key, 'in', this.className, this.fullPath,
            'because the path is missing.');
        } else {
          allPaths.push(this.adjustImportPath(path));
          keys.push(key.trim());
        }
      });

      if (allPaths.length > 0) {
        // Load all components in one getResources call
        promise = Utils.getResources(allPaths)
          .then((results) => {
            if (storeImport) {
              for (let index = 0; index < results.length; index += 1) {
                this.imports[keys[index]] = results[index];
              }
            }
          })
          .catch((reason) => {
            logger.error('Unable to load component:', reason.message, this.id);
            // swallow errors, just report them
          });
      }

      return promise;
    }
  }

  return Container;
});


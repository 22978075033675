'use strict';

define('vb/private/stateManagement/context/fragmentBaseContext',[
  'vb/private/stateManagement/context/baseContext'],
(BaseContext) => {
  /**
   * set of properties to expose in $base for fragment extension
   */
  class FragmentBaseContext extends BaseContext {
    /**
     * $base.fragment
     * @type {FragmentBaseContext}
     */
    get fragment() {
      return this;
    }
  }

  return FragmentBaseContext;
});


'use strict';

define('vb/private/services/boss/metadataTransforms',[
  'vb/private/services/boss/transformsUtils',
  'vb/private/types/dataProviderConstants'],
(TransformsUtils, DPConstants) => {
  // Constants
  const PROPERTIES = 'properties';
  const ORDER_BY = 'orderBy';
  const FILTER = 'filter';
  const LIMIT = 'limit';
  const OFFSET = 'offset';
  const QUERY = 'query';
  const ITERATION = 'iteration';
  const LOOKUP = 'lookup';
  const YES = 'yes';
  const X_SORTABLE = 'x-sortable';
  const X_SEARCHABLE = 'x-searchable';

  /**
   * Metadata Transforms pertaining to a service or endpoint.
   */
  class MetadataTransforms {
    /**
     * Returns the capabilities
     *
     * @param configuration
     * @return {{}}
     * @private
     */
    static capabilities(configuration) {
      const caps = {};

      const epDef = configuration.endpointDefinition;
      const responses = epDef.responses;

      // Get 'properties' from responses
      const schemaProperties = TransformsUtils.getPropertiesFromResponse(responses);
      const [properties] = (schemaProperties && TransformsUtils.searchProperty(schemaProperties, PROPERTIES)) || [{}];

      // Component parameters
      const paramsDef = epDef && epDef.componentParameters;
      if (paramsDef) {
        // Read 'orderBy' property from the Component parameters
        const orderByDef = TransformsUtils.getKeyValue(paramsDef, ORDER_BY);
        // Check if sort is supported by the endpoint
        if (orderByDef) {
          const oVal = orderByDef.value;
          if (oVal && oVal.in === QUERY) {
            // Include all the fields that are sortable in 'attributes'
            caps.sort = {
              attributes: Object.keys(properties)
                .filter((e) => !(properties[e][X_SORTABLE] === false)),
            };
          }
        }

        // Read 'filter' property from the Component parameters
        const filterDef = TransformsUtils.getKeyValue(paramsDef, FILTER);
        // Check if filter is supported by the endpoint
        if (filterDef) {
          const oVal = filterDef.value;
          if (oVal && oVal.in === QUERY) {
            // Include all the fields that are searchable in 'attributes'
            caps.filter = {
              operators: DPConstants.CapabilityValues.FILTER_OPERATORS_OPS,
              textFilter: false,
              attributes: Object.keys(properties)
                .filter((e) => !(properties[e][X_SEARCHABLE] === false)),
            };
            // To support fetching multiple keys
            caps.fetchByKeys = {
              implementation: LOOKUP,
              multiKeyLookup: YES,
            };
          }
        }

        // Read 'limit' property from the Component parameters
        const paginateDef = TransformsUtils.getKeyValue(paramsDef, LIMIT);
        // Check if paginate is supported by the endpoint
        if (paginateDef) {
          const oVal = paginateDef.value;
          if (oVal && oVal.in === QUERY) {
            caps.fetchFirst = {
              implementation: ITERATION,
            };
            // if the offset query param is present then there is fetchByOffset
            const offsetDef = TransformsUtils.getKeyValue(paramsDef, OFFSET);
            if (offsetDef) {
              const offsetVal = offsetDef.value;
              if (offsetVal && offsetVal.in === QUERY) {
                caps.fetchByOffset = {
                  implementation: DPConstants.CapabilityValues.FETCH_BY_OFFSET_IMPLEMENTATION_RANDOM_ACCESS,
                };
              }
            }
          }
        }
      }

      return caps;
    }

    /**
     * A function to create a Metadata transform object
     *
     * @returns {{capabilities: *}} an object representing the Metadata transforms
     */
    static toObject() {
      return {
        capabilities: MetadataTransforms.capabilities,
      };
    }
  }

  return MetadataTransforms;
});


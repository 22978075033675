'use strict';

define('vb/private/stateManagement/fragmentBridge',['vb/private/log', 'signals'], (Log, signals) => {
  const logger = Log.getLogger('/vb/stateManagement/fragmentBridge');
  const bridgeSymbol = Symbol('fragment-bridge-container');
  /**
   * This class is a bridge between the fragment CCA and the fragment instance identified by its unique id.
   * This is primarily used by vb-fragment CCA to get access to the FragmentContext for the fragment it is trying to
   * load/render using oj-module. The fragment context is associated to the parent Page, or fragment (if the CCA is
   * loading a nested fragment).
   *
   * @see PageContext
   * @see FragmentContext
   * @see oj-vb-fragment component for details
   *
   */
  class FragmentBridge {
    /**
     * Constructs a fragment bridge for the container using which individual fragment scopes are obtained by its
     * 'unique' id (set on the vb-fragment component).
     * @param {Page | Fragment} container that can have fragments. Example Page or Fragment
     * @return {FragmentBridge}
     */
    constructor(container) {
      const parent = container;
      Object.defineProperty(this, bridgeSymbol, {
        value: {
          get container() {
            return container;
          },
        },
      });

      // set the bridge on container
      parent.bridge = this;
      this.variableValueChanged = new signals.Signal();
      this.id = `${parent.id}-bridge`;
    }

    static getOrCreateBridge(container) {
      // usually the bridge that we use is associated to parent container. Example, say a page includes 2 fragments
      // like below - there is one bridge instance associated to the page that we grab if available or we create a
      // new one and associate to the page. The bridge is used to load/init/get a fragment(s).
      // <oj-vb-fragment id=frag1 name=frag1>
      // <oj-vb-fragment id=frag2 name=frag2>
      const bridge = container.bridge;
      if (!bridge) {
        return new FragmentBridge(container);
      }

      return bridge;
    }

    addVariableValueChangedListener(listener) {
      // When a write back listener is added for each fragment CCA it's tied to the bridge associated to the parent
      // container (page e.g.) because it is likely the page (or some parent) container variable that needs to be
      // updated.
      // Example -
      // <oj-vb-fragment id="frag1" name="frag1">
      //   <oj-vb-fragment-param name="frafoo" value="{{ $page.variables.foo }}"></oj-vb-fragment-param>
      // <oj-vb-fragment>
      return this.variableValueChanged.add(listener);
    }

    removeVariableValueChangedListener(listener) {
      return listener ? this.variableValueChanged.remove(listener) : this.variableValueChanged.removeAll;
    }

    getFragment(id, name, params) {
      const container = this[bridgeSymbol].container;
      return typeof container.getFragment === 'function' && container.getFragment(id, name, params);
    }

    connected(context, id) {
      const fragment = this.getFragment(id);
      // needed because JET appears to call disconnected callback on composites that have already been disconnected!
      if (!fragment) {
        logger.finer('fragment', id, 'has not been created yet');
        return;
      }
      fragment.componentConnected(context, id);
    }

    disconnected(domNodes, id) {
      this.removeVariableValueChangedListener();
      const fragment = this.getFragment(id);

      // needed because JET appears to call disconnected callback on composites that have already been disconnected!
      if (!fragment) {
        logger.finer('fragment', id, 'has already been disconnected from parent container');
        return;
      }
      fragment.componentDisconnected(domNodes);
    }

    /**
     * called from vb-fragment component to retrieve the ModuleConfig object.
     * @param {String} id of the fragment instance
     * @param {String} name of the fragment
     * @param {Object} params
     */
    getModuleConfig(id, name, params) {
      const fragment = this.getFragment(id, name, params);
      return fragment.getModuleConfig();
    }

    /**
     * wrapper to allow vb-fragment CCA to update a param value used by the fragment. This is called typically when
     * the value expression on oj-vb-fragment-param changes externally.
     * @param {String} id of the fragment instance
     * @param {string} name of the param to update
     * @param {object} value of the param to update
     * @return {boolean}
     */
    updateParam(id, name, value) {
      const fragment = this.getFragment(id);
      return fragment.updateParam(name, value);
    }

    /**
     * Called when the variable value has changed that needs to be applied on the mapped container variable.
     * @param {object} eventPayload
     * @param {string} eventPayload.name
     * @param {string} eventPayload.namespace
     * @param {any} eventPayload.value
     * @param {any} eventPayload.oldValue
     * @param {string} eventPayload.type
     * @param {object|array|*} eventPayload.diff
     */
    valueChanged(eventPayload) {
      this.variableValueChanged.dispatch(eventPayload);
    }
  }

  return FragmentBridge;
});


'use strict';

define('vb/private/services/definition/catalogFactory',[
  'vb/private/configLoader',
  'vb/private/services/definition/openApiServiceDefFactory',
  'vb/private/services/serviceUtils',
  'vbc/private/constants',
], (
  ConfigLoader,
  OpenApiServiceDefFactory,
  ServiceUtils,
  CommonConstants,
) => {
  const TYPE = 'catalogServices';

  /**
   * Class responsible finding and loading ServiceDefinitions declared in catalog.json files.
   */
  class CatalogFactory extends OpenApiServiceDefFactory {
    constructor(services, options) {
      super(services, options);

      this._catalogPaths = options.extensions && options.extensions.catalogPaths;

      this._initializeServiceMapPromise = null;
      this._serviceFileMap = {};
    }

    /**
     *
     * @returns {Promise<boolean>}
     * @override
     */
    findDeclaration(endpointReference) {
      return this._serviceFileMap[endpointReference.serviceName];
    }

    /**
     * Loads service declarations from catalog entries.
     * @override
     */
    updateServiceDeclarations() {
      if (!this._initializeServiceMapPromise) {
        const serviceFileMap = this._serviceFileMap;

        // first we load the catalog then add any new service entries to the serviceMap
        this._initializeServiceMapPromise = Promise.resolve()
          // We need to make sure that we register catalog names from the delegates.
          // TODO:  fix this as it is a side effect of searching for a service.
          .then(() => ServiceUtils.getAndRegisterAllCatalogNames(this.services))
          .then((catalogNames) => {
            // and add catalog "services", if any
            if (catalogNames && catalogNames.services
              && Array.isArray(catalogNames.services)) {
              catalogNames.services.forEach((name) => {
                const serviceId = name;
                if (!serviceFileMap[serviceId]) {
                  serviceFileMap[serviceId] = {
                    path: `${CommonConstants.VbProtocols.CATALOG}://services/${serviceId}`,
                    type: TYPE,
                  };
                }
              });
            }
          });
      }
      return this._initializeServiceMapPromise;
    }

    /**
     *
     * @returns {Promise<Object>}
     * @protected
     */
    getAndRegisterCatalogNames() {
      return Promise.resolve()
        .then(() => {
          if (this._catalogPaths) {
            Object.keys((this._catalogPaths))
              .forEach((key) => {
                // 'key' is (currently) same as extensionId aka this.namespace
                ConfigLoader.catalogRegistry.register(key, this._catalogPaths[key]);
              });
            // makes sure we register it only once
            this._catalogPaths = {};
            return ConfigLoader.catalogRegistry.getNames(this.services.namespace);
          }
          return null;
        });
    }
  }

  CatalogFactory.TYPE = TYPE;
  return CatalogFactory;
});


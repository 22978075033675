'use strict';

define('vbsw/private/plugins/tokenRelayHandlerPlugin',[
  'vbsw/api/fetchHandlerPlugin', 'vbsw/private/utils', 'vbsw/private/constants',
  'vbsw/private/plugins/authPreprocessorHandlerPlugin', 'vbc/private/log',
], (FetchHandlerPlugin, Utils, Constants, AuthPreprocessorHandlerPlugin, Log) => {
  const VB_TOKEN_RELAY_URL_HEADER = Constants.TOKEN_RELAY_URL_HEADER;
  const VB_TOKEN_RELAY_AUTHENTICATION_HEADER = Constants.TOKEN_RELAY_AUTH_HEADER;

  const logger = Log.getLogger('/vbsw/private/plugins/tokenRelayHandlerPlugin');

  /**
   * Handler plugin for handling token relay.
   */
  class TokenRelayHandlerPlugin extends FetchHandlerPlugin {
    constructor(context, params) {
      super(context);

      // used to cached in-memory version of the token for each token relay url
      this.cachedTokenPromises = {};

      // used to keep track of which token is currently invalid
      this.invalidateTokenPromises = {};

      // tolerance for the clock skew which can be configured by the app
      this.jwtClockSkewTolerance = (params && params.jwtClockSkewTolerance) || Constants.DEFAULT_CLOCK_SKEW_TOLERANCE;
    }

    static get tokenRelayUrlHeader() {
      return VB_TOKEN_RELAY_URL_HEADER;
    }

    static get tokenRelayAuthenticationHeader() {
      return VB_TOKEN_RELAY_AUTHENTICATION_HEADER;
    }

    /**
     * This method is meant to be subclassed to add additional headers specified in tokenRelayAuthentication to
     * the token relay request.
     *
     * @param request the token relay request to add the headers
     * @param tokeRelayAuthentication contains additional headers to add to request
     * @param requestUrl the url for the original request
     */
    processTokenRelayAuthentication(request, tokeRelayAuthentication, requestUrl) {}

    /**
     * Get the authorization token header either from cache or from the token relay service.
     *
     * @param tokenRelayUrl the url for the token relay service
     * @param tokenRelayAuthentication additional authentication metadata for token relay
     * @param requestUrl the url for the original request
     * @param {boolean} tokenRelay2 True if the token relay is for new Spectra TRAP endpoint
     * @returns {Promise}
     */
    getAuthHeader(tokenRelayUrl, tokenRelayAuthentication, requestUrl, tokenRelay2) {
      // cache the promise for getting the token so we don't make multiple calls to the token relay service
      let cachedTokenPromise = this.cachedTokenPromises[tokenRelayUrl];

      if (!cachedTokenPromise) {
        // first try retrieving the token from the state cache
        cachedTokenPromise = this.stateCache.get(tokenRelayUrl).then((cachedToken) => {
          if (cachedToken) {
            return cachedToken;
          }

          const options = {
            method: 'POST',
            credentials: 'same-origin',
            cache: 'no-cache', // instruct token relay to fetch a fresh token
            // need to add oracle cloud auth type header so mobile plugin knows to handle this
            headers: {
              [Constants.AUTHENTICATION_TYPE_HEADER]: Constants.AuthenticationType.ORACLE_CLOUD,
            },
          };

          if (tokenRelay2) {
            // new TRAP endpoint expects this grant_type
            const body = {
              grant_type: 'urn:oracle:implicit',
            };
            options.body = JSON.stringify(body);
          }
          const tokenRelayRequest = new Request(tokenRelayUrl, options);

          // process additional metadata specified in tokeyRelayAuthentication
          this.processTokenRelayAuthentication(tokenRelayRequest, tokenRelayAuthentication, requestUrl);

          // use the fetchHandler to fetch the token so the request can be modified by the plugins such as
          // csrfTokenHandlerPlugin
          return this.fetchHandler.handleRequest(tokenRelayRequest).then((response) => {
            if (response.ok) {
              return response.json().then((token) => this.cacheAuthToken(tokenRelayUrl, token));
            }

            throw new Error(response.statusText);
          });
        }).catch((err) => {
          // log the error for debugging purpose
          console.error(err);

          // delete the promise if there's any error so we don't cache the failure state
          delete this.cachedTokenPromises[tokenRelayUrl];
        });

        this.cachedTokenPromises[tokenRelayUrl] = cachedTokenPromise;
      }

      return cachedTokenPromise.then((cachedToken) => {
        if (cachedToken) {
          if (Utils.checkJwtExpiration(cachedToken.expiration, this.jwtClockSkewTolerance)) {
            // the token has expired, invalidate it and fetch a new one
            return this.invalidateCachedToken(tokenRelayUrl).then(() => {
              logger.info('Token for', tokenRelayUrl, 'has expired. Fetching a new token.');
              return this.getAuthHeader(tokenRelayUrl, tokenRelayAuthentication, requestUrl, tokenRelay2);
            });
          }

          // return the actual auth header
          return cachedToken.token.authenticationHeader;
        }

        return null;
      });
    }

    /**
     * Invalidate the cached token for the given tokenRelayUrl. This method will return a promise that will
     * resolve to true if a request should be retried and false otherwise.
     *
     * @param tokenRelayUrl the url for the cached token to invalidate
     * @returns {Promise.<Boolean>}
     */
    invalidateCachedToken(tokenRelayUrl, wwwAuthHeader) {
      let invalidateTokenPromise = this.invalidateTokenPromises[tokenRelayUrl];

      if (!invalidateTokenPromise) {
        // first, delete the token from the cache
        invalidateTokenPromise = this.stateCache.delete(tokenRelayUrl).then(() => {
          // BUFP-21346: The invalidate request currently does not work so we are bypassing it and relying on always
          // fetching a fresh token using the no-cache header.
          if (wwwAuthHeader) {
            const body = {
              headers: {
                'WWW-Authenticate': wwwAuthHeader,
              },
            };

            const options = {
              method: 'POST',
              credentials: 'same-origin',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(body),
            };
            const request = new Request(`${tokenRelayUrl}/invalidate`, options);

            // make the invalidate request to the token relay service
            return this.fetchHandler.handleRequest(request)
            // only retry if we get a status 307
              .then(response => response.status === 307)
              .catch((err) => {
                // log the error and don't retry
                console.error(err);
                return false;
              });
          }

          logger.info('Authorization token for', tokenRelayUrl, 'is invalidated');

          return Promise.resolve(true);
        });

        this.invalidateTokenPromises[tokenRelayUrl] = invalidateTokenPromise;
      }

      return invalidateTokenPromise.then((retry) => {
        // delete the invalidateTokenPromise
        delete this.invalidateTokenPromises[tokenRelayUrl];

        // delete the cachedTokenPromise so the token can be refreshed
        delete this.cachedTokenPromises[tokenRelayUrl];

        return retry;
      });
    }

    /**
     * The cached token is a wrapped version of the JWT token containing the extracted expiration
     * time and calculated server skew. This method will return a promise that resolves to the
     * wrapped token.
     *
     * @param tokenRelayUrl the url used to fetch the token
     * @param token the JWT token
     * @returns {Promise}
     */
    cacheAuthToken(tokenRelayUrl, token) {
      const expiration = Utils.extractJwtExpiration(token.authenticationHeader);
      const cachedToken = {
        token,
        expiration,
      };

      return this.stateCache.put(tokenRelayUrl, cachedToken).then(() => cachedToken);
    }

    /**
     * Append the token from the token relay service
     *
     * @param request the request to which to append the CSRF token
     * @returns {Promise}
     */
    handleRequestHook(request) {
      // get the url for the token relay service from the request header
      const tokenRelayUrl = request.headers.get(Constants.TOKEN_RELAY_URL_HEADER);

      if (tokenRelayUrl) {
        const tokenRelayAuthHeader = request.headers.get(Constants.TOKEN_RELAY_AUTH_HEADER);
        const tokenRelayAuthentication = tokenRelayAuthHeader ? JSON.parse(tokenRelayAuthHeader) : undefined;
        const tokenRelay2 = tokenRelayUrl ? request.headers.get(Constants.TRAP_2_ENABLED_HEADER) === 'true' : false;

        return this.getAuthHeader(tokenRelayUrl, tokenRelayAuthentication, request.url, tokenRelay2)
          .then((authHeader) => {
            if (authHeader) {
              // console.log(`authToke: ${token.authenticationHeader}`);
              const headers = request.headers;

              headers.set('Authorization', authHeader);
            }

            // failed to get the token and just let the original request fail
            return Promise.resolve();
          });
      }

      return Promise.resolve();
    }

    /**
     * Handles 401 response as result of a non-JWT token expiring. The returned promise will resolve
     * to true if the request needs to be retried and false otherwise.
     *
     * @param response
     * @param origRequest
     * @param request
     * @param client
     * @returns {Promise<Boolean>}
     */
    handleResponseHook(response, origRequest, request, client) {
      return Promise.resolve().then(() => {
        if (response.status === 401) {
          const authHeader = request.headers.get('Authorization');

          logger.info('401 response detected for', origRequest.url, 'with authorization header', authHeader);

          if (authHeader) {
            // extract the token relay url from the request
            const tokenRelayUrl = AuthPreprocessorHandlerPlugin.getTokenRelayUrlFromRequest(origRequest);

            logger.info('Look up token relay url', tokenRelayUrl);

            if (tokenRelayUrl) {
              const cachedTokenPromise = this.cachedTokenPromises[tokenRelayUrl] || Promise.resolve();

              return cachedTokenPromise.then((cachedToken) => {
                // if the auth header and the cached header match, that means the token has expired
                // so invalidate the token and retry the request
                if (cachedToken
                  && cachedToken.token.authenticationHeader === authHeader) {
                  logger.info('Invalidating cached authorization token');

                  return this.invalidateCachedToken(tokenRelayUrl).then(() => true);
                }

                return false;
              });
            }
          }
        }

        return false;
      });
    }
  }

  return TokenRelayHandlerPlugin;
});


'use strict';

define('vb/private/types/capabilities/fetchContext',[
  'vb/private/constants',
  'vb/private/log',
  'vb/private/action/assignmentHelper',
  'vb/private/utils',
  'vb/private/types/utils/dataProviderUtils',
  'vb/private/types/capabilities/serviceTransformsHookHandler',
  'vb/private/types/utils/serviceDataProviderRestHelperFactory',
  'vb/types/typeUtils',
  'ojs/ojdatasource-common',
  'ojs/ojeventtarget',
  'ojs/ojdataprovider'],
(Constants, Log, AssignmentHelper, Utils, DataProviderUtils, ServiceTransformsHookHandler, SDPRestHelperFactory,
  TypeUtils) => {
  /**
   * Default startIndex or offset (index) of rows to fetch
   * @type {number}
   * @private
   */
  const DEFAULT_OFFSET = 0;

  /**
   * Default page size, i.e., number of rows to fetch
   * @type {number}
   * @private
   */
  const DEFAULT_SIZE = 10;

  /**
   * Default max size used when size: -1 is provided
   * @type {number}
   */
  const DEFAULT_MAX_SIZE = 1000;

  /**
   * Default total size
   * @type {number}
   */
  const DEFAULT_TOTAL_SIZE = -1; // eslint-disable-line no-unused-vars

  /**
   * key used to store the transforms context for duration of iterator instance.
   * @type {string}
   */
  const INTERNAL_STATE_PROP_TRANSFORMS_CONTEXT = 'transformsContext';

  /**
   * Map of supported request transform types
   * @type {{
   *   VB_PREPARE: string, SORT: string, FILTER: string, PAGINATE: string, QUERY: string, SELECT: string, BODY: string,
   *   FETCH_BY_KEYS: string
   * }}
   */
  const REQUEST_TRANSFORM_TYPE = {
    VB_PREPARE: 'vbPrepare',
    SORT: 'sort',
    FILTER: 'filter',
    PAGINATE: 'paginate',
    QUERY: 'query',
    SELECT: 'select',
    BODY: 'body',
    FETCH_BY_KEYS: 'fetchByKeys',
  };

  /**
   * Map of response transform types
   * @type {{BODY: string, PAGINATE: string}}
   */
  const RESPONSE_TRANSFORM_TYPE = {
    BODY: 'body',
    PAGINATE: 'paginate', // currently has 3 properties totalSize / hasMore / pagingState (for
    // any other state users want to store and pass)
  };

  /**
   * shared logger for all FetchContext instances
   * @type {Log}
   */
  const LOGGER = Log.getLogger('/vb/types/ServiceDataProvider.FetchContext', undefined);

  /**
   * process failure outcome from fetch call and rethrow error.
   */
  const processFailure = (uniqueId, logger, err) => {
    logger.finer('ServiceDataProvider', uniqueId, 'fetch failed with error:', err);
    return (err);
  };

  /**
   * process success outcome by resolving with result from fetch call
   */
  const processSuccess = (uniqueId, logger, result) => {
    logger.finer('ServiceDataProvider', uniqueId, 'fetch succeeded with result:', result);
    return (result);
  };

  /**
   * A context object that is applicable for the duration of the fetch call.
   */
  class FetchContext {
    /**
     * Creates a context object that represents the state of the SDP when the fetch call is made.
     * This context object is used for the duration of the fetch cycle. For fetchFirst calls
     * the asyncIterator represents the context object that stores additional state between
     * iterations as well.
     * @param {ServiceDataProvider | ServiceDataProvider2} sdp
     * @param {object} params
     * @constructor
     */
    constructor(sdp, params) {
      this.log = LOGGER;
      this.id = Utils.generateUniqueId();
      this.sdp = sdp;
      /**
       * snapshot of SDP variable state at the time the context object was created. For the
       * duration of the fetch this context will be maintained (any new updates to the SDP
       * configuration will not be reflected here).
       * @returns {object} with value and defaultValue properties
       */
      this.sdpState = {
        value: Utils.cloneObject(sdp.getValue()) || {},
        defaultValue: Utils.cloneObject(sdp.getDefinitionValue()) || {},
      };
      /**
       * options provided via the fetch call by caller that have been white listed.
       */
      this.fetchOptions = this.whiteListFetchOptions(params);
      this.originalFetchOptions = params;
      // attach a listener to the SDP variable stage signal, to be notified of the lifecycle changes
      this.lifecycleStageChangedListener = this.handleLifecycleStageChanged();
      // we pass a context object that represents the context this listener is added in. The same listener could be
      // called for different instances of this class
      this.sdp.getLifecycleStageChangedSignal().add(this.lifecycleStageChangedListener, { id: this.id });
      this.internalState = {};
    }

    handleLifecycleStageChanged() {
      return (lifecycleStage, sdpWrapper) => this
        .handleVariableLifecycleStageChange(lifecycleStage, sdpWrapper);
    }

    /**
     * handle lifecycle stage change by using the provided SDP instance.
     * @param stage
     * @param sdp
     */
    handleVariableLifecycleStageChange(stage, sdp) {
      if (stage === Constants.VariableLifecycleStage.DISPOSE) {
        // we don't change the cached this.sdpState because it's already a cloned copy, just the sdp is changed
        this.sdp = sdp;
        this.sdp.getLifecycleStageChangedSignal().remove(this.lifecycleStageChangedListener, { id: this.id });
      }
    }

    static get DEFAULT_SIZE() {
      return DEFAULT_SIZE;
    }

    static get DEFAULT_OFFSET() {
      return DEFAULT_OFFSET;
    }

    static get DEFAULT_TOTAL_SIZE() {
      return DEFAULT_TOTAL_SIZE;
    }

    static get DEFAULT_MAX_SIZE() {
      return DEFAULT_MAX_SIZE;
    }

    static get REQUEST_TRANSFORM_TYPE() {
      return REQUEST_TRANSFORM_TYPE;
    }

    static get RESPONSE_TRANSFORM_TYPE() {
      return RESPONSE_TRANSFORM_TYPE;
    }

    static get LOGGER() {
      return LOGGER;
    }

    /**
     * whether the property value set on the variable can be used when building up transform
     * options/functions. For external fetches every thing needs to be configured on the
     * chain action, and any value set on variable will be ignored. But for internal fetch
     * this check returns true.
     *
     * @param propName
     * @param state of the SDP variable
     * @returns {boolean}
     * @static
     */
    static usePropValue(propName, state) {
      const IGNORED_PROPS = ['sortCriteria', 'filterCriteria', 'filterCriterion', 'pagingCriteria',
        'transforms', 'uriParameters', 'body'];
      const ALLOWED_PROPS = ['mergeTransformOptions'];
      // ignore using value for specific properties, if SDP variable definition does not have the
      // property defined for external fetches. In reality DT will copy over all properties
      // defined in SDP over to the Rest Action configuration. Also the
      const { fetchChainId } = state;
      if (fetchChainId) {
        return !(IGNORED_PROPS.indexOf(propName) >= 0) || (ALLOWED_PROPS.indexOf(propName) >= 0);
      }
      return true;
    }

    /**
     * Subclasses can override to whitelist options.
     * @param {Object=} options
     * @returns {*}
     */
    whiteListFetchOptions(options) { // eslint-disable-line class-methods-use-this
      return options;
    }

    /**
     * Builds the parameters to pass to the actionChain.
     * @returns {Object} with a 'configuration' property.
     */
    getActionChainParams() {
      const configuration = {};
      configuration.hookHandler = new ServiceTransformsHookHandler(this);
      return { configuration };
    }

    /**
     * Creates a rest helper instance priming it with the configured options.
     * @returns {Rest|null} resthelper instance or null
     */
    createRestHelper() {
      const sdpValue = this.sdpState.value;
      const body = Utils.cloneObject(sdpValue.body);
      const uriParameters = Utils.cloneObject(this.getQueryOptions() || sdpValue.uriParameters);
      const initConf = {};
      const transformsContext = this.getFetchTransformsContext();

      if (sdpValue.headers && Object.keys(sdpValue.headers).length > 0) {
        initConf.headers = Utils.cloneObject(sdpValue.headers);
      }
      const serviceTransformsHook = new ServiceTransformsHookHandler(this);

      // if the SDP has a createRestHelper() function, use it to create a fresh one, using the same endpoint.
      // (we need a new one when fetching multiple keys, each one needs to be unique)
      const restHelper = this.sdp.createRestHelper
        ? this.sdp.createRestHelper()
        : (sdpValue.endpoint && SDPRestHelperFactory.get(sdpValue.endpoint, this.sdp.container));

      if (restHelper) {
        return restHelper
          .parameters(uriParameters)
          .hookHandler(serviceTransformsHook)
          .initConfiguration(initConf)
          .body(body)
          .transformsContext(transformsContext);
      }
      return null;
    }

    /**
     * Fetches data, using the SDP state of the current context, by either doing an implicit
     * rest call or by using an action. This is usually common for all types of fetch calls -
     * fetchFirst/next, fetchByKeys, fetchByOffset.
     *
     * @returns {Promise}
     */
    fetch() {
      return Promise.resolve().then(() => {
        const uniqueId = `${this.sdp.id} [${this.id}]`;
        const state = this.sdpState.value;

        // for js chain, the chain id specified via fetchChain instead of fetchChainId
        const { fetchChainId, fetchChain } = state;
        const isJsChain = !!fetchChain;
        const chainId = fetchChain || fetchChainId;

        // call the actionChain identified by the 'fetchChainId' if specified, otherwise
        // fallback to calling rest helper directly
        if (chainId) {
          const chainParams = this.getActionChainParams();
          this.log.finer('ServiceDataProvider', uniqueId,
            'initiating fetch using action chain:', fetchChainId,
            'with parameters:', chainParams);

          return this.sdp.callActionChain(chainId, chainParams, isJsChain).then((response) => {
            // for js chain, the result is not wrapped in an outcome
            if (isJsChain) {
              return processSuccess(uniqueId, this.log, response);
            }
            if (response.name === 'success') {
              return processSuccess(uniqueId, this.log, response.result);
            }
            throw (processFailure(uniqueId, this.log, response.result));
          }).catch((err) => {
            throw processFailure(uniqueId, this.log, err);
          });
        }
        // this is an implicit SDP.
        const { uriParameters } = state;

        const rest = this.createRestHelper();

        if (rest) {
          this.log.finer('ServiceDataProvider', uniqueId, 'initiating fetch against endpoint:', rest.getName(),
            'with parameters:', JSON.stringify(uriParameters));

          return rest.fetch().then((result) => {
            const actualResult = {
              status: result.response.status,
              headers: result.response.headers,
              body: result.body,
            };

            if (result.response.ok) {
              // TODO: <bug> we need a way to specify the response type and not assume it's application/json
              // if we have a responseType, coerce the response body to that type BUFP-14902 and 16042: when page is
              // unloaded before REST returns we end up in situation where variable is undefined. Guard against that.
              if (!this.sdp.getValue()) {
                const msg = `unable to process response with status ${actualResult.status} from endpoint`
                  + ` '${rest.getName()}' as the ServiceDataProvider instance no longer exists!`;
                throw (processFailure(uniqueId, this.log, msg));
              }

              const resolvedType = this.getResponseType();
              if (resolvedType && !DataProviderUtils.isTypeDefIncomplete(resolvedType)) {
                // pick attributes from the return value into an object that matches the type
                const beforeCoercion = actualResult.body;
                actualResult.body = AssignmentHelper.coerceType(beforeCoercion, resolvedType);
                this.log.finer('ServiceDataProvider', uniqueId, 'response', beforeCoercion,
                  'was coerced to', actualResult.body);
              }

              return processSuccess(uniqueId, this.log, actualResult);
            }
            throw processFailure(uniqueId, this.log, actualResult);
          }).catch((err) => {
            throw processFailure(uniqueId, this.log, err);
          });
        }

        throw processFailure(uniqueId, this.log, 'invalid service or endpoint name');
      });
    }

    /**
     * Retrieves iterator specific state stored in SDP internalState
     * @param key
     * @returns {*}
     */
    getInternalState(key) {
      const iteratorKey = `${this.id}.${key}`;
      return TypeUtils.getPropertyValue(this.internalState, iteratorKey);
      // return this.sdp.getInternalState(iteratorKey);
    }

    /**
     * Uses SDP internalState to store iterator specific state that are generally computed.
     * The key is the unique iterator id. InternalState stored by iterator will be cleared
     * when iterator is done and hence garbage collected.
     * @param key fetchContext id is automatically pre-pended. No key is needed to clear
     * everything
     * @param value
     */
    setInternalState(key, value) {
      // Iterator instance itself should hold its internalState
      const iteratorKey = !key ? `${this.id}` : `${this.id}.${key}`;
      const stateValue = Object.assign({}, this.internalState);
      TypeUtils.setPropertyValue(stateValue, iteratorKey, value);
      this.internalState = stateValue;

      const actionText = value === undefined ? 'clears' : 'sets';
      this.log.finer('iterator', this.id, actionText, 'internal state for SDP:', this.sdp.id,
        'with key:', iteratorKey, ':', value);
    }

    /**
     * The fetch capability this class implements by default. Subclasses must override this
     * method.
     * @returns {string}
     * @abstract
     */
    getFetchCapability() { // eslint-disable-line class-methods-use-this
      throw new Error('fetch capability cannot be determined!');
    }

    /**
     * Returns the responseType for the current context. If a responseType was not set it's
     * assumed to be 'any'.
     * Subclasses must override to provide the contextual response type.
     * @public
     */
    getResponseType() {
      const { sdp } = this;
      const sdpValue = this.sdpState.value;
      const sdpDefaultValue = this.sdpState.defaultValue;
      let rType;

      if (FetchContext.usePropValue('responseType', sdpValue)) {
        // we use the defaultValue (not value) of 'responseType' to resolve the type, in case a
        // string type is set, because we want to determine the actual resolved type (definition).
        const { responseType } = sdpDefaultValue;
        return DataProviderUtils.getResolvedType(responseType, sdp, true /* generateDefaultType */);
      }
      return rType;
    }

    /**
     * Returns transformsContext to use for current fetch (or for iterators the cached one). If there is a cached
     * transformsContext, we use it over what's set in SDP variable or what's passed in via fetchParameters. The
     * context is stored in cache in processResponseTransforms.
     * @returns {{}|*}
     */
    getFetchTransformsContext() {
      const cachedTransformsContext = this.getInternalState(INTERNAL_STATE_PROP_TRANSFORMS_CONTEXT);

      if (cachedTransformsContext && Object.keys(cachedTransformsContext).length > 0) {
        return Utils.cloneObject(cachedTransformsContext); // clone transformsContext to unwrap
      }

      return this.getTransformsContext();
    }

    /**
     * Returns a cloned transforms context object that is configured on the SDP variable by
     * default. Subclasses can override this method to provide a more complete transforms
     * context if needed.
     * @returns {{}}
     */
    getTransformsContext() {
      const sdpValue = this.sdpState.value;
      return Object.assign({}, sdpValue.transformsContext);
    }

    /**
     * Sets up the request and response transform options/functions on the restHelper usually
     * before a fetch call, based on the snapshot-ed state of SDP variable.
     *
     * The transform options determined by the FetchContext is merged on top of the ones set
     * on the Rest helper.
     *
     * @param rest the rest helper instance
     * @private
     */
    setupTransforms(rest) {
      // 1. determine the transform options to pass to each transform function. This is a 3
      // step process

      // 1.1 get the transform options from the fetch implementation
      const uniqueId = `${this.sdp.id} [${this.id}]`;
      const reqTransformOptions = this.getRequestTransformOptions();

      // 1.2. merge the options defined on the rest helper with those determined by fetch implementation
      const mergedTO = this.reconcileTransformOptions(reqTransformOptions, rest.transformRequestOptionsMap);

      // 1.3 finally if a merge (options) function is set in the configuration call it to get
      // final transform options. The page author's transforms has the final word.
      const finalTO = this.callMergeTransformsOptionsFunc(mergedTO, rest);

      // 1.4. set the final transform options on the helper.
      rest.requestTransformationOptions(finalTO);
      // if query options have changed update the rest instance parameters with it. Doing it now is fine because the
      // rest helper is in pre-fetch hook state. Also update transformsContext
      let restParams = Utils.cloneObject(rest.params);
      const queryTO = finalTO.query;
      if (typeof queryTO === 'object' && Object.keys(queryTO).length > 0) {
        restParams = Object.assign({}, restParams, queryTO);
        rest.parameters(restParams);
      }

      rest.transformsContext(this.getFetchTransformsContext());

      // 1.5. also if there is a body, now is a good time to set it on the rest helper
      if (finalTO.body) {
        rest.body(finalTO.body);
      }

      this.log.finer('ServiceDataProvider', uniqueId, 'final request transform options:', finalTO);

      // 2. determine the final transform functions to use, and set them on the Rest Helper.
      const reqTransformFunctions = this.getRequestTransformFunctions();
      const resTransformFunctions = this.getResponseTransformFunctions();
      if (reqTransformFunctions) {
        const mergedReqTF = Object.assign({}, rest.transformRequestFuncMap, reqTransformFunctions);
        rest.requestTransformationFunctions(mergedReqTF);
        this.log.finer('ServiceDataProvider', uniqueId, 'final request transform functions:', mergedReqTF);
      }

      if (resTransformFunctions) {
        const mergedResTF = Object.assign({}, rest.transformResponseFuncMap, resTransformFunctions);
        rest.responseTransformationFunctions(mergedResTF);
        this.log.finer('ServiceDataProvider', uniqueId, 'final response transform functions:', mergedResTF);
      }
    }

    /**
     * Merges the rest transform options with the transform options determined for the current
     * fetch by SDP. This method can be overridden if implementations want special logic.
     *
     * @param transformOptions
     * @param otherTransformOptions primarily from rest.
     */
    reconcileTransformOptions(transformOptions, otherTransformOptions) { // eslint-disable-line class-methods-use-this
      return Object.assign({}, otherTransformOptions, transformOptions);
    }

    /**
     * Calls the merge transforms options function if configured. This allows page author to
     * tweak the transform options that SDP determined as the best guess values, for each of the
     * transform function types like query, paginate, filter etc.
     *
     * @param inputTO object of transform options that SDP determines as the best guess values
     * for options to pass to the namesake transform functions.
     * @param rest helper instance
     * @private
     */
    callMergeTransformsOptionsFunc(inputTO, rest) {
      let finalTO = inputTO;
      const restTO = rest.transformRequestOptionsMap;
      // sdpState.value was already retrieved via a variable.getValue() call, which clones the value. We clone it
      // again before passing callback so author does not muck with the reference we hold.
      const clonedSDPState = Utils.cloneObject(this.sdpState.value);
      const configuration = {
        context: clonedSDPState,
        externalContext: Utils.cloneObject(restTO),
        fetchParameters: Utils.cloneObject(this.fetchOptions),
        capability: this.getFetchCapability(),
      };

      // push the fetch configuration details on the Rest Helper instance so transform functions can have it
      rest.setFetchConfiguration(configuration);

      const func = this.getMergeTransformOptionsFunc();
      if (func && typeof func === 'function') {
        const uniqueId = `${this.sdp.id} [${this.id}]`;
        const to = func.call(null, configuration, inputTO);
        this.log.finer('ServiceDataProvider', uniqueId,
          'mergeTransformOptions function called with input options:', inputTO,
          '. The final merged transform options for the fetch:', to);
        finalTO = to;
      }

      this.setInternalState('finalTransformsOptions', finalTO);
      // update the configuration with the final transformsOptions for transforms authors to use in their functions
      configuration.transformsOptions = Utils.cloneObject(finalTO);
      return finalTO;
    }

    /**
     * Returns the final map of request transform options keyed in by the name of the
     * requestTransformation function. Each subclass of FetchContext can override this method
     * to setup transforms. The default behavior of determining options is implemented here.
     *
     * The values for each transform function key is built using these properties:
     *  - pagingCriteria: options specific to the paging style. E.g., offset/size. This option is
     *    passed to the 'paging' request transform function.
     *  - sortCriteria:   an array of objects specific to the sorting. E.g., 'full' style has
     *    'name' and 'direction' properties. This array is passed to the 'sort' request
     *    transform function.
     *  - filterCriterion: options specific to filtering. This option is passed to the
     *    'filter' requestTransformFunction.
     *
     * @private
     */
    getRequestTransformOptions() {
      const finalTOpts = {};
      const uniqueId = `${this.sdp.id} [${this.id}]`;

      const paginateOptions = this.getPaginateOptions();
      if (paginateOptions) {
        finalTOpts[REQUEST_TRANSFORM_TYPE.PAGINATE] = paginateOptions;
      }

      const sortOptions = this.getSortOptions();
      if (sortOptions) {
        finalTOpts[REQUEST_TRANSFORM_TYPE.SORT] = sortOptions;
      }

      const filterOptions = this.getFilterOptions();
      if (filterOptions) {
        finalTOpts[REQUEST_TRANSFORM_TYPE.FILTER] = filterOptions;
      }

      const queryOptions = this.getQueryOptions();
      if (queryOptions) {
        finalTOpts[REQUEST_TRANSFORM_TYPE.QUERY] = queryOptions;
      }

      const selectOptions = this.getSelectOptions();
      if (selectOptions) {
        finalTOpts[REQUEST_TRANSFORM_TYPE.SELECT] = selectOptions;
      }

      const bodyOptions = this.getBodyOptions();
      if (bodyOptions) {
        finalTOpts[REQUEST_TRANSFORM_TYPE.BODY] = bodyOptions;
      }

      this.log.finer('ServiceDataProvider', uniqueId, 'hook handler setting request transform'
        + ' options:', finalTOpts);

      return finalTOpts;
    }

    /**
     * Returns the filterCriteria or filterCriterion options. Subclasses can override to
     * determine options based on its fetch call context.
     * @returns {*}
     */
    getFilterOptions() {
      const fetchOpts = this.fetchOptions || {};
      const sdpValue = this.sdpState.value;
      const sdpDefaultValue = this.sdpState.defaultValue;

      // if filterCriteria (deprecated) property is set then use this over filterCriterion, in
      // order to not break existing customers. This means that filterCriterion options passed
      // in via fetch() will be ignored (select/combo). Also 'filterCriteria' is rarely
      // called by components but can be provided by other callers (such as a CCA - it's preferred
      // that these callers use filterCriterion instead).
      let filterCriteria;
      if (FetchContext.usePropValue('filterCriteria', sdpValue) && sdpDefaultValue.filterCriteria) {
        filterCriteria = sdpValue.filterCriteria || [];
        return filterCriteria;
      }

      // if filterCriterion is provided by caller always use it and cache on iterator,
      // else use the SDP configured value if allowed.
      let filterCriterion;
      const fetchOptsFC = fetchOpts.filterCriterion;
      if (FetchContext.usePropValue('filterCriterion', sdpValue) && !fetchOptsFC) {
        filterCriterion = sdpValue.filterCriterion || {};
      } else if (fetchOptsFC && Object.keys(fetchOptsFC).length > 0) {
        filterCriterion = fetchOptsFC;
      }

      return filterCriterion;
    }

    /**
     * Returns pagingCriteria options generally passed to the paginate request transform function.
     * Subclasses can override to determine options based on its fetch call context.
     * @returns {{offset: (*|number), size: (*|number)}}
     */
    getPaginateOptions() {
      const { sdp } = this;
      const fetchOpts = this.fetchOptions || {};
      const sdpValue = this.sdpState.value;

      let variablePCOffset;
      let variablePCSize;
      if (FetchContext.usePropValue('pagingCriteria', sdpValue)) {
        const variablePC = sdpValue.pagingCriteria || {};
        variablePCOffset = variablePC.offset;
        variablePCSize = variablePC.size;
      }
      const size = fetchOpts.size >= 0 ? fetchOpts.size : variablePCSize || DEFAULT_SIZE;
      const offset = fetchOpts.offset >= 0 ? fetchOpts.offset : variablePCOffset || DEFAULT_OFFSET;

      const pagingCriteria = { offset, size };
      sdp.log.finer('calling rest with new pagingCriteria:', JSON.stringify(pagingCriteria));
      return pagingCriteria;
    }

    /**
     * Returns the sortCriteria options. If sortCriteria is provided via fetch options, always
     * use this over the SDP snapshot value for sortCriteria. Subclasses can override to
     * determine options based on its fetch call context.
     * @returns {*}
     */
    getSortOptions() {
      let sortCriteria;
      const fetchOpts = this.fetchOptions || {};
      const sdpValue = this.sdpState.value;
      const fetchOptsSC = fetchOpts.sortCriteria;

      if (FetchContext.usePropValue('sortCriteria', sdpValue)
        && (!fetchOptsSC || fetchOptsSC.length === 0)) {
        sortCriteria = sdpValue.sortCriteria || [];
      } else if (fetchOptsSC && fetchOptsSC.length > 0) {
        sortCriteria = fetchOptsSC;
      }
      return sortCriteria;
    }

    /**
     * Returns the body set via configuration.
     * @returns {*}
     */
    getBodyOptions() {
      let body;
      const sdpValue = this.sdpState.value;

      if (FetchContext.usePropValue('body', sdpValue)) {
        ({ body } = sdpValue);
      }
      return body;
    }

    /**
     * Returns the uriParameters set on the sdp variable in the current fetch context. Subclasses
     * can override to determine options based on its fetch call context.
     * @returns {*}
     */
    getQueryOptions() {
      const sdpValue = this.sdpState.value;
      let queryParams;

      if (FetchContext.usePropValue('uriParameters', sdpValue)) {
        queryParams = sdpValue.uriParameters;
      }
      return queryParams;
    }

    /**
     * Get the select options, which are the fields (nested and top-level) based on the
     * responseType set. Subclasses can override to determine options based on its fetch call
     * context.
     * @returns {*}
     */
    getSelectOptions() {
      let selectOptions;

      // get the portion of the type that represents the 'itemsPath', if possible
      const resolvedType = this.getResponseType();
      if (resolvedType) {
        selectOptions = {
          type: resolvedType,
        };
      }

      return selectOptions;
    }

    /**
     * Returns a Map of the request transform functions. This can be set on the RestHelper or
     * passed to the RestAction.
     * @private
     */
    getRequestTransformFunctions() {
      const { sdp } = this;
      const sdpValue = this.sdpState.value;
      const uniqueId = `${this.sdp.id} [${this.id}]`;

      let finalReqTF;
      if (FetchContext.usePropValue('transforms', sdpValue)) {
        finalReqTF = {};
        const transformFunctions = (sdpValue.transforms && sdpValue.transforms.request) || {};
        Object.values(REQUEST_TRANSFORM_TYPE).forEach((prop) => {
          if (transformFunctions[prop]) {
            finalReqTF[prop] = transformFunctions[prop];
          }
        });
      }

      sdp.log.finer('ServiceDataProvider', uniqueId, 'hook handler setting request transform'
        + ' functions:', finalReqTF);
      return finalReqTF;
    }

    /**
     * Returns the request transform options function.
     * @private
     */
    getMergeTransformOptionsFunc() {
      const sdpValue = this.sdpState.value;
      let func;
      if (FetchContext.usePropValue('mergeTransformOptions', sdpValue)) {
        const to = sdpValue.mergeTransformOptions;
        // anything other than func is ignored.
        if (to && typeof to === 'function') {
          func = to;
        }
      }

      return func;
    }

    getResponseTransformFunctions() {
      const { sdp } = this;
      const sdpValue = this.sdpState.value;
      const uniqueId = `${this.sdp.id} [${this.id}]`;
      let finalResTF;

      if (FetchContext.usePropValue('transforms', sdpValue)) {
        finalResTF = {};
        const transformFunctions = (sdpValue.transforms && sdpValue.transforms.response) || {};

        Object.values(RESPONSE_TRANSFORM_TYPE).forEach((type) => {
          if (transformFunctions[type]) {
            finalResTF[type] = transformFunctions[type];
          }
        });
      }
      sdp.log.finer('ServiceDataProvider', uniqueId, 'hook handler setting response transform'
        + ' functions:', finalResTF);
      return finalResTF;
    }

    /**
     * The fetchOptions to provide with the response to caller that might include additional configuration besides
     * what was requested via fetch call.
     * @returns {Object|undefined}
     */
    getFetchOptionsForResponse() {
      const isValidFilter = (fc) => (
        (fc.op && fc.attribute && fc.value) || (Array.isArray(fc.criteria) && fc.criteria.length > 0));
      const fTO = this.getInternalState('finalTransformsOptions');
      /**
       * @type {Object|{filterCriterion: Object, sortCriteria: array}}
       */
      let finalOptionsOverride;
      if (fTO && fTO.filter && isValidFilter(fTO.filter)) {
        finalOptionsOverride = finalOptionsOverride || {};
        finalOptionsOverride.filterCriterion = fTO.filter;
      }
      if (fTO && fTO.sort && Array.isArray(fTO.sort) && fTO.sort.length > 0) {
        finalOptionsOverride = finalOptionsOverride || {};
        finalOptionsOverride.sortCriteria = fTO.sort;
      }
      if (this.fetchOptions || finalOptionsOverride) {
        return Object.assign({}, this.fetchOptions, finalOptionsOverride);
      }
      return this.fetchOptions;
    }

    /**
     * Fires a notification event on the container that this instance variable belongs to.
     * @param severity
     * @param detail
     */
    invokeNotificationEvent(severity, detail) {
      // fire a dataProviderMessage event that allows authors to handle error appropriately
      const uniqueId = `${this.sdp.id} [${this.id}]`;
      const eventPayload = {
        severity,
        detail,
        capability: this.getFetchCapability(),
        fetchParameters: this.fetchOptions,
        context: Utils.cloneObject(this.sdpState.value),
        id: uniqueId,
        key: Utils.generateUniqueId(), // this event can be fired multiple times in some
        // cases, this ids the event each time
      };
      this.sdp.invokeEvent(Constants.DATAPROVIDER_NOTIFICATION_EVENT, eventPayload);
    }

    /**
     * processes the response transform results. Subclasses must override to do any special
     * handling of response. This method stashes the transformsContext in the internal state
     * cache for next iteration.
     *
     * transformsContext, if returned from a response transform results, is held in the internal
     * state and then later set 'as is' on the RestHelper for the subsequent fetch / next call.
     *
     * @param transformResults
     * @return Promise that resolves when the response transforms has been processed
     */
    processResponseTransforms(transformResults) { // eslint-disable-line class-methods-use-this
      return Promise.resolve().then(() => {
        const tc = transformResults[INTERNAL_STATE_PROP_TRANSFORMS_CONTEXT];
        if (tc && Object.keys(tc).length > 0) {
          this.setInternalState(INTERNAL_STATE_PROP_TRANSFORMS_CONTEXT, tc);
        }
      });
    }
  }

  return FetchContext;
});


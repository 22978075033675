'use strict';

define('vb/private/services/serviceUtils',[
  'urijs/URI',
  'vb/private/configLoader',
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/services/serviceConstants',
  'vb/private/services/swaggerUtils',
  'vb/private/services/trapData',
  'vbc/private/constants',
], (
  URI,
  ConfigLoader,
  Constants,
  Utils,
  ServiceConstants,
  SwaggerUtils,
  TrapData,
  CommonConstants,
) => {
  const HAS_SCHEME_REGEX = /^[A-Za-z][A-Za-z0-9+.-]*:.+/;

  const getTrapData = (vbInitConfig) => TrapData.getTrapData(vbInitConfig);

  /**
   *
   */
  class ServiceUtils {
    /**
     * Checks if we should use new TRAP services (VB or remote)
     *
     * @param {Object} [vbInitConfig = window.vbInitConfig || {}]
     * @returns {boolean}
     */
    static shouldUseTrapService(vbInitConfig = window.vbInitConfig || {}) {
      return getTrapData(vbInitConfig).shouldUseTrapService();
    }

    /**
     * Gets the scope value for the Trap service.
     * It defaults to "urn:opc:resource:fusion:<FA pod name>:rwdinfraapp", but the pattern can be
     * overriden via TRAP_SERVICE.TRAP_SERVICE_SCOPE value in the vbInitConfig.
     *
     * @param {Object} vbInitConfig
     * @returns {string}
     */
    static getTrapServiceScope(vbInitConfig = window.vbInitConfig || {}) {
      return getTrapData(vbInitConfig).getTrapServiceScope();
    }

    /**
     * Gets the URL for proxy endpoint for the given service name.
     *
     * @param {Object} options
     * @param {string} options.serviceName
     * @param {string} [options.activeProfile = null] Active profile if available
     */
    static getProxyUrl(options) {
      return getTrapData(options.vbInitConfig).getProxyUrl(options);
    }

    /**
     * Gets the URL for token relay endpoint for the given service name.
     *
     * @param {Object} options
     * @param {string} options.serviceName
     * @param {string} [options.activeProfile=null] Active profile if available
     */
    static getTokenRelayUrl(options) {
      return getTrapData(options.vbInitConfig).getTokenRelayUrl(options);
    }

    /**
     * https://confluence.oraclecorp.com/confluence/pages/viewpage.action?pageId=936840736
     * https://jira.oraclecorp.com/jira/browse/BUFP-26454
     * https://jira.oraclecorp.com/jira/browse/BUFP-27537
     */
    /**
     * Augment the extension with information that can be used to construct the proxy url and token relay url.
     *
     * For the name used in the proxy, use the first "services" name from the catalog, if any.
     * Otherwise, use the normal service name (from the app-flow key).
     *
     * @param name name of the service; this must be the name used for the PROXY url.
     * @param catalogInfoChain optional, array from additional info from resolving a vb-catalog:// path, if any
     * @param extensions extension to augment
     * @private
     */
    static augmentExtension(name, catalogInfoChain, extensions) {
      if (extensions) {
        // if the catalog was used, use the first service name for the proxy; otherwise, use the normal service name
        const firstInChain = (catalogInfoChain && catalogInfoChain[0]) || {};
        const nameForUrl = (firstInChain.type === 'services' && firstInChain.name) || name;

        const ext = Utils.cloneObject(extensions);

        ext.serviceName = nameForUrl;

        // Since we already have serviceName we can fully resolve URLs here and not just pass baseUrl(s)
        // we can not use "proxyUrl" for property name as it is used for legacy support
        const urlOptions = {
          serviceName: nameForUrl,
          extensionId: firstInChain.namespace,
          extensionVersion: firstInChain.version,
          activeProfile: ConfigLoader.activeProfile,
        };
        ext.resolvedProxyUrl = ServiceUtils.getProxyUrl(urlOptions);
        ext.resolvedTokenRelayUrl = ServiceUtils.getTokenRelayUrl(urlOptions);
        ext.trapEnabled = ServiceUtils.shouldUseTrapService();
        if (ext.trapEnabled) {
          ext.trapScope = ServiceUtils.getTrapServiceScope();
        }

        return ext;
      }
      return extensions;
    }

    /**
     * this was originally in endpoint.js
     *
     * first, check if the URL is 'http', and if so, switch to https, because we use the actual url to make the fetch,
     * and it has to bee https when the app is served from https. We put the original protocol in a special header.
     * When we construct the proxy in the fetch plugins, we look for the special header, and use that protocol instead.
     *
     * next, look to see if the currentPage needs auth, and add another header flag if so.
     *
     * @todo: this should be adding this information in the special 'vb-info-extension' header
     * to keep ALL information passed to the preprocessor in ONE header.
     *
     * note that we need the auth for the current page, but we do not want static dependency on Router in this file
     *
     * @param headers
     * @param url
     * @param isAnonymousAllowed Router.getCurrentPage() && !Router.getCurrentPage().isAuthenticationRequired()
     * @returns {string} the current URL, or a new URL if a protocol override is needed
     */
    static getHeadersAndUrlForPreprocessing(headers, url, isAnonymousAllowed) {
      const hdrs = headers;
      let newUrl = url;

      let added = false;

      if (ServiceUtils.isProtocolOverrideRequired(url)) {
        const uri = new URI(url);
        uri.protocol('https');
        hdrs[CommonConstants.Headers.PROTOCOL_OVERRIDE_HEADER] = 'http';
        newUrl = uri.toString();
        added = true;
      }

      if (isAnonymousAllowed) {
        hdrs[CommonConstants.Headers.ALLOW_ANONYMOUS_ACCESS_HEADER] = true;
        added = true;
      }

      // if we already created the 'vb-info-extension' header, update it with the new headers
      const infoExtension = added && headers[CommonConstants.Headers.VB_INFO_EXTENSION];
      if (infoExtension) {
        const extensions = SwaggerUtils.parseServiceText(infoExtension);
        extensions.headers = Object.assign({}, hdrs);
        // eslint-disable-next-line no-param-reassign
        headers[CommonConstants.Headers.VB_INFO_EXTENSION] = JSON.stringify(extensions);
      }

      return newUrl;
    }

    /**
     * used to be in endpoint.js
     *
     * @returns {boolean} True if the protocol of the window is http but the requested url is http
     */
    static isProtocolOverrideRequired(url) {
      return window.location.protocol.indexOf('https') === 0 && url.indexOf('http:') === 0;
    }

    /**
     * note, this is different than DefinitionObject._mergeExtensions;
     * here, everything in extensionB takes precedence over extensionsA
     *
     * @param extensionA
     * @param extensionB
     * @returns {*}
     * @private
     */
    static mergeExtensions(extensionA, extensionB) {
      const extA = extensionA || {};
      const extB = extensionB || {};

      // headers are merged
      const headers = Object.assign({}, extA.headers || {}, extB.headers || {});

      // authentication is overridden
      const authentication = Object.assign({}, extB.authentication || extA.authentication || {});

      // everything else is overriden
      const merged = Object.assign({}, extA, extB, { headers });

      // don't add an empty 'authorization' object, just to keep it clean-ish, and keep the 'clutter' low
      if (Object.keys(authentication).length) {
        merged[ServiceConstants.AUTH_DECL_NAME] = authentication;
      }
      return merged;
    }

    /**
     * "metadata" in this context refers to the use of an "openapi" fragment in a catalog.json "services" object.
     *
     * used when catalog.json uses the "paths" syntax for referencing a service definition;
     * (this is NOT used for normal data fetch; only live swagger/openapi3).
     *
     * the object returned from here may also me used as an "init" object for a Request/fetch, since it contains the
     * headers for the metadata.
     *
     * @param serverUrl
     * @param metadata created by CatalogHandler.createOpenApiMetadata
     * @param requestInit for Request/fetch API.  typically contains "headers" object (and other unused values).
     * @returns {{headers: *, method: *, url: *, transforms: { path: {string} }}}
     * @private
     */
    static getExtensionsFromMetadata(serverUrl, metadata, requestInit) {
      const uri = new URI(serverUrl).segment(metadata.path || '');

      // the 'metadata' is populated by the new openapi3 syntax in catalog, if the URL has a query in "paths/get".
      // note that the serverUrl may also have a query.
      if (metadata.query) {
        if (uri.query()) {
          uri.addQuery(URI.parseQuery(metadata.query));
        } else {
          uri.query(metadata.query);
        }
      }

      const url = uri.normalize().toString();

      const method = metadata.method.toUpperCase();
      const metadataExtensions = metadata.extensions || {};
      const initHeaders = requestInit.headers || {};
      // eslint-disable-next-line max-len
      const serverExtensions = SwaggerUtils.parseServiceText(initHeaders[CommonConstants.Headers.VB_INFO_EXTENSION] || '{}');

      const mergedHeaders = Object.assign({}, requestInit.headers || {}, metadataExtensions.headers || {});
      delete mergedHeaders[CommonConstants.Headers.VB_INFO_EXTENSION];

      // extensions in the "paths" override conflicts with "server" extensions
      // note: we keep url & method here, so this can be used as an init
      const mergedExtensions = Object
        .assign({ url, method }, serverExtensions, metadataExtensions, { headers: mergedHeaders });

      mergedExtensions.headers = mergedHeaders;

      // create an cleaner object for the 'vb-info-extension' header
      const extForHeader = Object.assign({}, mergedExtensions);
      delete extForHeader.url;
      delete extForHeader.method;

      // set a new, combined, vb-info-extension headers
      mergedHeaders[CommonConstants.Headers.VB_INFO_EXTENSION] = JSON.stringify(extForHeader);

      return mergedExtensions;
    }

    /**
     * @typedef {Object} TransformedResolvedCatalogObject
     * @property {*} url
     * @property {string} namespace
     * @property {Object} services
     * @property {Object} services.extensions
     * @property {Object} services.extensions.headers
     * @property {*} services.metadata
     * @property {Object} backends
     * @property {Object|*} backends.extensions
     * @property {*} metadata
     * @property {[]|*} chain
     * @property {*} mergedExtensions
     */
    /**
     * @param resolved
     * @param name
     * @param url
     * @param existingServiceDefHeaders
     * @returns {TransformedResolvedCatalogObject}
     */
    static transformResolvedCatalogObject(resolved, name, url, existingServiceDefHeaders) {
      // convert the 'resolved' object to something a little easier for the runtime to consume right now
      // reach into the metadata & headers, and separate the top-level by services & backends
      // BEFORE: metadata: {services: {}}, headers: { services: {}, backends: {} }...
      // AFTER: services: { metadata: {}, headers: {} }, backends: { headers: {} }

      // get the 'services' header
      const extensions = (resolved && resolved.extensions) || {};

      // merge the 'backends' extensions _into_ the 'services' extensions
      // the catalogHandler/protocolRegistry keeps the 'services' and 'backends' data separate;
      // its up to the caller to decide how to interpret those in relation to each other.
      // VB considers any referenced 'backends' extension info to be part of the 'services' that references it,
      // so merge the backends into the services, giving the services conflicts priority.
      // This means, a services will inherit references backends' auth block, headres, etc.
      let mergedExtensions = ServiceUtils
        .mergeExtensions(extensions.backends || {}, extensions.services || {});

      const headers = mergedExtensions.headers || {};
      // similiar to endpoint.js, we need to communicate some 'x-vb' information to the service worker plugins
      // BUFP-25539: need to pass proxyUrls to the plugins, if defined in the catalog 'services' object
      // so the fetch of the service definition goes through the proxy

      const serviceNameForProxy = ServiceUtils.getNameForProxy(name, url, resolved.chain);

      // @todo: is the chain right?
      mergedExtensions = ServiceUtils
        .augmentExtension(serviceNameForProxy, resolved.chain, mergedExtensions);

      headers[CommonConstants.Headers.VB_INFO_EXTENSION] = JSON.stringify(mergedExtensions);

      // any catalog 'headers' are merged with existing (declared) headers (declared ones take precedence).
      const mergedHeaders = Object.assign({}, headers || {}, existingServiceDefHeaders || {});

      const metadata = resolved.metadata || {};

      return {
        url: resolved.url,
        namespace: resolved.namespace, // 'base' or <ext ID>
        services: {
          extensions: {
            headers: mergedHeaders, // only 'headers' are used for service def loads
          },
          metadata: metadata.services,
        },
        backends: {
          extensions: extensions.backends || {},
          // no backend metadata currently; metadata: metadata.backends,
        },
        metadata: resolved.metadata,
        chain: resolved.chain || [],
        mergedExtensions, // @todo: better name? adding this only for mappper
      };
    }

    /**
     * We need to make sure that we register catalog names from all of the services delegates. If we don't do this,
     * their backends are only "activated" if at least one of their services was used before
     * (if the backend is not "activated", it's not visible to other extensions).
     * Without this server of a Service from this extension may not be resolvable if it references backend
     * from a delegate.
     *
     * @param {Services} services
     */
    static getAndRegisterAllCatalogNames(services) {
      // calls services._serviceDefFactories['catalogServices'].getAndRegisterCatalogNames()
      const callArray = ['_serviceDefFactories', 'catalogServices', 'getAndRegisterCatalogNames'];
      return Promise.resolve()
        .then(() => services.searchDelegates(
          (delegate) => Promise.resolve().then(() => Utils.safelyCall(delegate, ...callArray)).then(() => false),
        ))
        .then(() => Utils.safelyCall(services, ...callArray));
    }

    /**
     * @param {string} value
     * @return {boolean} true if value starts with a valid URI scheme
     */
    static hasScheme(value) {
      return HAS_SCHEME_REGEX.test(value);
    }

    /**
     * Returns an absolute URL by resolving 'url' against 'baseUrl' if necessary, i.e. if url is a relative URL.
     * Returns 'url' in case of errors.
     *
     * @param {string} baseUrl - the base URL
     * @param {string} url - the URL to be turned into an absolute URL
     * @return {string} an absolute URL or 'url' in case of errors
     */
    static toAbsoluteServerUrl(baseUrl, url) {
      // ensures that the resolution only happens if 'baseUrl' is absolute and 'url' is relative
      return ServiceUtils.hasScheme(baseUrl) && !ServiceUtils.hasScheme(url) ? new URI(url, baseUrl).href() : url;
    }

    /**
     *
     * @param name the 'name' of the service, from the declaration key
     * @param path file path, could be absolute or relative, and could use a custom protocol (vb-catalog).
     * @param chain the trail derived from custom protocol resolution (aka 'vector through the catalog.json')
     * @returns {*|boolean}
     */
    static getNameForProxy(name, path, chain) {
      const firstInChain = chain && chain[0];

      // first, use the name of any referenced "services" object from the catalog (if any)
      // "services": { "wrongname": { "path": "vb-catalog://services/rightname/blah/blah" } }
      // the 'chain keeps track of how we followed custom protocol indirections ("vb-catalog")
      // vb-catalog://services/foo -> vb-catalog://backends/a -> vb-catalog://backends/b, etc.
      let proxyName = (firstInChain && firstInChain.type === 'services' && firstInChain.name);

      // next, look at the file path, if the catalog was not used (no chain) and the path is relative
      // example: "./services/myname/openapi3.json". must have a /services/ followed by another folder name.
      if (!proxyName && (!chain || !chain.length) && !Utils.isAbsolutePath(path)) {
        const parts = path.split('/');
        const idx = parts.indexOf('services');
        if (idx >= 0 && idx === parts.length - 3) {
          proxyName = parts[idx + 1];
        }
      }

      return proxyName || name;
    }

    /**
     * Returns a simple map-like object, that enforces key = ID + namespace.
     * getAll() function returns all values with the given ID + namespaces, regadless of any extra varibales
     *
     * @returns {{
     *  get: (function(string, string): *),
     *  getAll: (function(string, string): *),
     *  set: (function(string, string, *): void),
     *  setWithVariables: (function(string, string, string, *): void),
     *  has: (function(string, string): boolean),
     *  hasWithVariables: (function(string, string, string): boolean),
     *  getWithVariables: (function(string, string, string): boolean),
     *  getKeys: (function(): string[]),
     *  getValues: (function(): *[]),
     *  delete: (function( function(string, string) : boolean ): void)
     * }}
     */
    static createNamespaceMap(defaultNamespace = Constants.ExtensionNamespaces.BASE) {
      /** @type Object<string, any> */
      const m = {};
      /** @type {function(string, string, string=): string} */
      // eslint-disable-next-line max-len
      const computeKey = (id, namespace, variablesKey) => `${id}:${namespace || defaultNamespace}:${variablesKey || ''}`;
      return {
        get: (id, namespace) => m[computeKey(id, namespace)],
        getAll: (id, namespace) => {
          const nonVarKey = computeKey(id, namespace);
          const allNonVarValues = [];
          Object.keys(m).forEach((k) => {
            if (k.startsWith(nonVarKey)) {
              allNonVarValues.push(m[k]);
            }
          });
          return allNonVarValues;
        },

        set: (id, namespace, value) => {
          m[computeKey(id, namespace)] = value;
        },

        setWithVariables: (id, namespace, variablesKey, value) => {
          m[computeKey(id, namespace, variablesKey)] = value;
        },

        has: (id, namespace) => !!m[computeKey(id, namespace)],

        hasWithVariables: (id, namespace, variablesKey) => !!m[computeKey(id, namespace, variablesKey)],
        getWithVariables: (id, namespace, variablesKey) => m[computeKey(id, namespace, variablesKey)],

        getKeys: () => Object.keys(m),
        getValues: () => Object.values(m),

        // delete entries when the condition callback returns true
        // @returns {function<id: {string}, namespace: {string}> : boolean}
        delete: (condition) => {
          const toDelete = [];
          Object.keys(m).forEach((k) => {
            const parts = k && k.split(':');
            if (condition(parts[0], parts[1])) {
              toDelete.push(k);
            }
          });
          toDelete.forEach((k) => {
            delete m[k];
          });
        },
      };
    }
  }

  return ServiceUtils;
});


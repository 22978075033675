'use strict';

define('vb/private/services/boss/requestTransforms',[
  'urijs/URI',
  'vb/private/log',
  'vb/private/services/boss/transformsUtils'],
(URI, Log, TransformsUtils) => {
  // Get logger
  const logger = Log.getLogger('/vb/private/services/boss/requestTransforms');
  const VB_TEXT_FILTER_ATTRS = 'vb-textFilterAttributes';
  const ORDER_BY = 'orderBy';
  const FILTER = 'filter';
  const LIMIT = 'limit';
  const OFFSET = 'offset';
  const FIELDS = 'fields';
  const FETCH_BY_KEYS = 'fetchByKeys';

  // SCIM operator
  const scimOp = {
    eq: '=',
    ne: '!=',
    gt: '>',
    ge: '>=',
    lt: '<',
    le: '<=',
    sw: 'sw',
    ew: 'ew',
    co: 'co',
    pr: 'pr',
  };

  // Operators supported by BOSS other than SCIM operator
  const additionalOp = ['=', '!=', '>', '>=', '<', '<=', 'LIKE', '~=', '~!=', '~>', '~>=', '~<', '~<=', '~LIKE',
    'IS NOT NULL', 'IS NULL', 'IN'];

  /**
   * Request Transforms
   */
  class RequestTransforms {
    /**
     * A method to append key/value pair to the URL
     *
     * @param url a URL
     * @param key a parameter name
     * @param value a parameter value
     * @return url a URL string
     */
    static addURLParameter(url, key, value) {
      // Check if URL includes '?'
      const keyValSeparator = url.includes(RequestTransforms.QUERY_QUESTION_MARK_OP)
        ? RequestTransforms.QUERY_AMPERSAND_OP : RequestTransforms.QUERY_QUESTION_MARK_OP;

      // Get the string representation of the value
      const valStr = value === null || value === undefined ? value : `${value}`;

      // Check if value is already encoded, if not encode value
      const encodeValStr = valStr && (valStr === URI.decodeQuery(valStr)) ? URI.encodeQuery(valStr) : valStr;

      // Append encoded value to the URL
      return key && encodeValStr ? `${url}${keyValSeparator}${key}=${encodeValStr}` : url;
    }

    /**
     * Get query parameter name from component parameter object
     *
     * @param config request configuration object
     * @param param component parameter
     * @return {string} query parameter name
     */
    static getQueryParameterName(config, param) {
      const componentParameters = config && config.endpointDefinition && config.endpointDefinition.componentParameters;
      return (componentParameters && componentParameters[param] && componentParameters[param].name) || param;
    }

    /**
     * Build sort expression from sort criteria
     *
     * @param criteria an object representing the sort criteria
     * @returns {string} sort expression
     */
    static buildSortExpr(criteria) {
      return criteria.attribute + (criteria.direction === 'ascending' ? ':asc' : ':desc');
    }

    /**
     * Function to check if given parameter exists in the URL
     *
     * @param url endpoint URL
     * @param param a URL parameter
     * @returns {boolean} return true if param exists in the url | false
     */
    static urlParamExists(url, param) {
      return URI(url).hasQuery(param);
    }

    /**
     * Enclose string value with in single quotes
     *
     * @param value value to be encoded
     * @returns {string} value with single quotes if it is of type string
     */
    static encodeValue(value) {
      // To escape a single quote inside a string, precede it with another single quote
      const str = (typeof value === 'string') && value.replace(/'/g, '\'\'');
      return typeof str === 'string' ? `'${str}'` : value;
    }

    /**
     * Convert a text filter criterion object into expression
     *
     * @param fc a single text filter criterion object
     * @param ctx a transforms context object that stores contextual information for the duration of the request.
     * @returns {string} text filter criterion expression
     */
    static convertTextFilterCriterionToExpr(fc, ctx) {
      const expr = '';

      // Get text filter attributes array from the transforms context
      const textFilterAttributes = ctx && ctx[VB_TEXT_FILTER_ATTRS];

      if (Array.isArray(textFilterAttributes) && fc.text !== null && fc.text !== undefined) {
        const textFilterCriterion = { op: '$or', criteria: [] };

        // Generate filter criterion for each text attribute and populate textFilterCriterion
        textFilterAttributes.forEach((attribute) => {
          const textFilterCriteria = { op: '$sw', attribute, value: fc.text };
          textFilterCriterion.criteria.push(textFilterCriteria);
        });

        // Build filter expression from the generated textFilterCriterion
        return RequestTransforms.buildFilterExpression(textFilterCriterion, ctx);
      }

      return expr;
    }

    /**
     * Convert an attribute filter criterion object into expression
     *
     * @param fc a single attribute filter criterion object
     * @param ctx a transforms context object that stores contextual information for the duration of the request.
     * @returns {string} attribute filter criterion expression
     */
    static convertAttributeFilterCriterionToExpr(fc, ctx) {
      const expr = '';

      // Function to process attribute value. It could be a sub-object.
      const processFilterAttrValue = (op, attribute, value) => {
        // Process value if it is an object
        if (value && typeof value === 'object' && !Array.isArray(value)) {
          return Object.entries(value).reduce((acc, [attr, val]) => (typeof val === 'object'
            ? acc.concat(processFilterAttrValue(op, `${attribute}.${attr}`, val))
            : acc.concat({ op, attribute: `${attribute}.${attr}`, value: val })), []);
        }
        return [{ op, attribute, value }];
      };

      if (fc && typeof fc === 'object' && !Array.isArray(fc) && fc.op && fc.value
        && typeof fc.value === 'object' && !Array.isArray(fc.value)) {
        const attrFilterCriterion = { op: '$and', criteria: [] };

        // Generate filter criterion for each property in value and populate attrFilterCriterion
        Object.keys(fc.value).forEach((attribute) => {
          const attrFilterCriteria = processFilterAttrValue(fc.op, attribute, fc.value[attribute]);
          attrFilterCriterion.criteria.push(...attrFilterCriteria);
        });

        // Build filter expression from the generated attrFilterCriterion
        return RequestTransforms.buildFilterExpression(attrFilterCriterion, ctx);
      }

      return expr;
    }

    /**
     * Convert an attribute expression filter criterion object into expression.
     *
     * @param fc an attribute expression filter criterion object
     * @returns {string} an attribute expression filter criterion expression
     */
    static convertAttrExprFilterCriterionToExpr(fc) {
      let opValue = '';
      let op = '';

      // Check if it is a SCIM operator
      if (fc.op && fc.op.startsWith('$')) {
        const opStr = fc.op.substring(1);
        op = scimOp[opStr] ? scimOp[opStr] : op;
      } else if (additionalOp.includes(fc.op)) { // check if it is in additional operators
        op = fc.op;
      }

      switch (op) {
        case 'sw':
          opValue = `LIKE '${fc.value}%'`;
          break;
        case 'ew':
          opValue = `LIKE '%${fc.value}'`;
          break;
        case 'co':
          opValue = `LIKE '%${fc.value}%'`;
          break;
        case 'pr':
        case 'IS NOT NULL':
          opValue = '!= null';
          break;
        case 'IS NULL':
          opValue = '= null';
          break;
        case 'IN':
          if (Array.isArray((fc.value))) {
            const valueExpr = fc.value.map((v) => RequestTransforms.encodeValue(v))
              .join(',');
            opValue = `${fc.op} (${valueExpr})`;
          } else {
            logger.warn('skipping, value is not supported by IN operator:', fc.value);
          }
          break;
        default:
          opValue = op ? `${op} ${RequestTransforms.encodeValue(fc.value)}` : '';
          break;
      }
      // Convert it into filter expression
      return opValue ? `${fc.attribute} ${opValue}` : opValue;
    }

    /**
     * Convert a filter criteria object into expression. It could be a text filter or an attribute filter
     * or an attribute expression filter.
     *
     * @param fc a single filter criteria object
     * @param ctx a transforms context object that stores contextual information for the duration of the request.
     * @returns {string} filter criteria expression
     */
    static convertFilterCriteriaToExpr(fc, ctx) {
      // Process text filter
      if (fc.text !== null && fc.text !== undefined) {
        return RequestTransforms.convertTextFilterCriterionToExpr(fc, ctx);
      }

      // Process attribute filter
      if (fc.attribute === null || fc.attribute === undefined) {
        return RequestTransforms.convertAttributeFilterCriterionToExpr(fc, ctx);
      }

      // Process attribute expression filter
      return RequestTransforms.convertAttrExprFilterCriterionToExpr(fc);
    }

    /**
     * Build filter expression from a filterCriteria array or filterCriterion object
     *
     * @param fc filter criteria object (single or nested)
     * @param ctx a transforms context object that stores contextual information for the duration of the request.
     * @returns {string|undefined} filter criteria expression | undefined
     */
    static buildFilterExpression(fc, ctx) {
      if (fc && typeof fc === 'object') {
        // Process filter criteria array
        if (Array.isArray(fc) && fc.length > 0) {
          return RequestTransforms.buildFilterExpression({ op: '$and', criteria: fc }, ctx);
        }

        // Process filter criterion object with criteria property
        if (Array.isArray(fc.criteria) && fc.criteria.length > 0) {
          // Iterate over each filter object and build expression
          const subExprArr = fc.criteria.reduce(
            (acc, cur) => [...acc, RequestTransforms.buildFilterExpression(cur, ctx)], [],
          );

          // Join together individual filter expressions
          const filterSubExpr = subExprArr.length > 0 ? subExprArr.join(` ${fc.op === '$or' ? 'or' : 'and'} `) : '';

          // Wrap around nested expression with parenthesis
          return filterSubExpr ? `(${filterSubExpr})` : filterSubExpr;
        }

        // Convert single filter object into expression
        return RequestTransforms.convertFilterCriteriaToExpr(fc, ctx);
      }
      return undefined;
    }

    /**
     * A method to transform the request for pagination.
     *
     * @param configuration a request configuration object
     * @param options an options object
     * @returns {*} configuration object with updated URL
     */
    static paginate(configuration, options/* , context */) {
      const config = configuration;
      if (options) {
        // Map size to limit parameter and restrict request for unlimited rows to 1000
        if (options.size !== null && options.size !== undefined) {
          const param = RequestTransforms.getQueryParameterName(config, LIMIT);
          if (!RequestTransforms.urlParamExists(config.url, param)) {
            config.url = RequestTransforms.addURLParameter(config.url, param, options.size < 0 ? 1000 : options.size);
          }
        }

        if (options.offset !== null && options.offset !== undefined && options.offset >= 0) {
          const param = RequestTransforms.getQueryParameterName(config, OFFSET);
          if (!RequestTransforms.urlParamExists(config.url, param)) {
            config.url = RequestTransforms.addURLParameter(config.url, param, options.offset);
          }
        }
      }
      return config;
    }

    /**
     * A method to transform the request for sorting.
     *
     * @param configuration a request configuration object
     * @param options an options object
     * @returns {*} configuration object with updated URL
     */
    static sort(configuration, options/* , context */) {
      const config = configuration;
      const key = RequestTransforms.getQueryParameterName(config, ORDER_BY);
      const isUrlParamExist = RequestTransforms.urlParamExists(config.url, key);
      if (!isUrlParamExist && options && Array.isArray(options) && options.length > 0) {
        const value = options.reduce((acc, curr) => (acc && curr && curr.attribute
          ? `${acc},${RequestTransforms.buildSortExpr(curr)}` : acc + RequestTransforms.buildSortExpr(curr)), '');
        config.url = RequestTransforms.addURLParameter(config.url, key, value);
      }
      return config;
    }

    /**
     * A method to transform the request for filtering.
     *
     * @param configuration a request configuration object
     * @param options an options object
     * @param context a transforms context object to store contextual information for the duration of the request.
     * @returns {*} configuration object with updated URL
     */
    static filter(configuration, options, context) {
      const config = configuration;

      const param = RequestTransforms.getQueryParameterName(config, FILTER);
      if (RequestTransforms.urlParamExists(config.url, param)) {
        return config;
      }

      const filterExpression = RequestTransforms.buildFilterExpression(options, context);

      if (filterExpression) {
        config.url = RequestTransforms.addURLParameter(config.url, param, filterExpression);
      }
      return config;
    }

    /**
     * A method to transform the request for selecting fields.
     *
     * @param configuration a request configuration object
     * @param options an options object
     * @returns {*} configuration object with updated URL
     */
    static select(configuration, options/* , context */) {
      const config = configuration;
      const urlParam = RequestTransforms.getQueryParameterName(config, FIELDS);
      let fields;

      // Do nothing if its not a GET or if 'fields' parameter already exists in the url
      if ((config.initConfig && config.initConfig.method !== 'GET')
          || RequestTransforms.urlParamExists(config.url, urlParam)) {
        return config;
      }

      // Function to filter out fields that starts with $
      const isReserved = (name) => name[0] === '$';
      const getFields = (item) => (Array.isArray(item) ? item.filter((f) => !isReserved(f)) : []);

      // Function to prefix subObject name to the field names
      const prefixSubObjName = (subObjectName, fieldsArray) => {
        if (Array.isArray(fieldsArray) && !isReserved(subObjectName)) {
          return fieldsArray.map((f) => `${subObjectName}.${f}`);
        }
        return [];
      };

      // Function to process attributes property
      const processAttributes = (fieldsArray) => Array.isArray(fieldsArray) && fieldsArray.reduce(
        (acc, item) => (item && !item.attributes ? [...acc, ...getFields([item.name])]
          : [...acc, ...prefixSubObjName(item.name, processAttributes(item.attributes))]), [],
      );

      // Function to process items property
      const processItems = (items) => {
        const fieldNames = [];
        const itemsArr = Array.isArray(items) ? items : [items || {}];
        itemsArr.forEach((item) => {
          const names = TransformsUtils.isObject(item) && Object.keys(item).reduce(
            (acc, key) => (TransformsUtils.isObject(item[key])
              ? [...acc, ...prefixSubObjName(key, processItems(item[key].items || item[key]))]
              : [...acc, key]), [],
          );
          fieldNames.push(...getFields(names));
        });
        return fieldNames;
      };

      // Get fields from the option type
      let fieldsItem = (options && options.type && options.type !== 'any' && (options.type.items || options.type));

      if (fieldsItem) {
        fields = processItems(fieldsItem);
      } else {
        // Get fields from the option attributes
        fieldsItem = (options && options.attributes);
        fields = processAttributes(fieldsItem);
      }

      if (Array.isArray(fields) && fields.length > 0) {
        config.url = RequestTransforms.addURLParameter(config.url, urlParam, fields.join(','));
      }
      return config;
    }

    /**
     * A method to get one or more keys.
     *
     * @param {Object} configuration
     * @param configuration.fetchConfiguration configuration for the current fetch call
     * @param configuration.endpointDefinition metadata for the endpoint
     * @returns {*} configuration object with updated URL
     */
    static fetchByKeys(configuration) {
      const fetchConfig = configuration.fetchConfiguration;
      const fetchCall = fetchConfig.capability;
      const fetchKeys = fetchConfig.fetchParameters.keys;
      const epDef = configuration.endpointDefinition;
      const endpointParams = epDef.componentParameters || {};
      const filterParam = endpointParams && TransformsUtils.getKeyValue(endpointParams, FILTER);

      // Call select transform to append 'fields' parameter to the URL
      if (fetchConfig.fetchParameters && fetchConfig.fetchParameters.attributes) {
        RequestTransforms.select(configuration, fetchConfig.fetchParameters);
      }

      if (fetchCall === FETCH_BY_KEYS && filterParam && filterParam.key === FILTER) {
        if (fetchKeys && fetchKeys instanceof Set && fetchKeys.size > 0) {
          const idAttribute = fetchConfig.context.keyAttributes || fetchConfig.context.idAttribute;
          const keyAttributes = [];
          if (idAttribute && typeof idAttribute === 'string') {
            keyAttributes.push(idAttribute);
          } else if (Array.isArray(idAttribute)) {
            keyAttributes.push(...idAttribute);
          } else {
            // The idAttribute/keyAttributes is not provided by the user. Look for x-primaryKey
            if (!epDef.xPrimaryKey) {
              // Not cached yet. Read 'x-primaryKey' from the response
              const properties = TransformsUtils.getPropertiesFromResponse(epDef.responses);
              const xPrimaryKey = properties && TransformsUtils.searchProperty(properties, 'x-primaryKey');
              // Cache xPrimaryKey
              epDef.xPrimaryKey = Array.isArray(xPrimaryKey) ? xPrimaryKey.pop() : [];
            }
            keyAttributes.push(...epDef.xPrimaryKey);
          }

          if (Array.isArray(keyAttributes) && keyAttributes.length > 0) {
            const keyCriterion = {
              op: '$and',
              criteria: [],
            };

            // Generate filter criterion for each value in keyAttributes
            if (keyAttributes.length === 1) {
              keyCriterion.criteria.push(
                {
                  op: 'IN',
                  attribute: keyAttributes[0],
                  value: [...fetchKeys],
                },
              );
            } else {
              // Convert fetchKeys set to array
              const fetchKeysArray = [...fetchKeys];
              const attributeKeys = {};
              keyAttributes.forEach((attr) => { attributeKeys[attr] = []; });

              // Filter out each key value and map it to the attribute in attributeKeys object
              fetchKeysArray.forEach((key) => {
                // Check if key matches with keyAttributes
                if (key.length === keyAttributes.length) {
                  for (let i = 0; i < key.length; i += 1) {
                    attributeKeys[keyAttributes[i]].push(key[i]);
                  }
                }
              });
              keyAttributes.forEach((attr) => keyCriterion.criteria.push(
                {
                  op: 'IN',
                  attribute: attr,
                  value: attributeKeys[attr],
                },
              ));
            }
            return RequestTransforms.filter(configuration, keyCriterion);
          }
          logger.warn('skipping, invalid key attributes');
        }
      }

      return configuration;
    }

    /**
     * A function to create a Request transform object
     *
     * @returns {{filter: *, fetchByKeys: *, select: *, paginate: *, sort: *}} an object representing
     * the Request transforms
     */
    static toObject() {
      return {
        paginate: RequestTransforms.paginate,
        sort: RequestTransforms.sort,
        filter: RequestTransforms.filter,
        select: RequestTransforms.select,
        fetchByKeys: RequestTransforms.fetchByKeys,
      };
    }
  }

  RequestTransforms.QUERY_AMPERSAND_OP = '&';
  RequestTransforms.QUERY_QUESTION_MARK_OP = '?';

  return RequestTransforms;
});


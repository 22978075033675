'use strict';

define('vb/private/stateManagement/fragmentExtension',[
  'vb/private/stateManagement/containerExtension',
  'vb/private/stateManagement/context/fragmentExtensionContext',
  'vb/private/constants',
  'vb/private/utils',
  'vb/helpers/mixin',
  'vb/private/stateManagement/packageFragmentReferenceable',
  'vb/private/stateManagement/fragmentModuleViewModel',
], (ContainerExtension, FragmentExtensionContext, Constants, Utils, Mixin, PackageFragmentReferenceable,
  FragmentModuleViewModel) => {
  /**
   * FragmentExtension class used when a fragment is extended from base. In addition the base fragment could contain
   * a dynamic container; and customer could extend fragment along with the dynamic-container template
   */
  class FragmentExtension extends ContainerExtension {
    constructor(extension, path, base, className = 'FragmentExtension') {
      super(extension, null, path, base, className);
      /**
       * instances of loaded fragments used by this container.
       * @type {Object}
       */
      this.fragments = {};
      this.inputParams = base.inputParams;
    }

    /**
     * A extension fragment can only reference a fragment defined in base extension. It's not possible for an
     * extension fragment to include 'local' fragments (local to the current extension). At the moment only new
     * pages / layout (under ui/self) in an extension can define and use local fragments.
     * Example for fragments defined under <ext-A>/.../ui/self/fragments,
     *   (1) extension fragment template defined under <ext-B>/.../ui/ext-A/.../fragment-template-x.html can reference
     *   fragment from ext-A or some upstream extension
     *   (2) new fragment in ext-B can reference ext-A fragments or local fragments. This class is not involved for
     *   a new fragment
     *
     * @return {*}
     * @constructor
     */
    static get FragmentClass() {
      return PackageFragmentReferenceable;
    }

    disposeFragments() {
      Object.keys(this.fragments).forEach((fragmentId) => {
        const frag = this.fragments[fragmentId];
        frag.dispose();
      });
    }

    /**
     * deletes a fragment from the container.
     * @param id
     */
    deleteFragment(id) {
      delete this.fragments[id];
    }

    static get extensionClass() {
      return FragmentExtension;
    }

    /**
     * @type {string}
     */
    static get resourceSuffix() {
      return '-fragment-x.json';
    }

    /**
     * The default event prefix is the lowercase class name (see container.js) but for
     * fragment extension we want to use the same event prefix as fragment
     *
     * @type {String}
     */
    // eslint-disable-next-line class-methods-use-this
    get eventPrefix() {
      return 'fragment';
    }

    /**
     * The name of the runtime environment function to be used to load the descriptor
     *
     * @type {String} the descriptor loader function name
     */
    static get descriptorLoaderName() {
      return 'getFragmentExtensionDescriptor';
    }

    /**
     * The name of the runtime environment function to be used to load the module functions.
     *
     * @type {String} the module loader function name
     */
    static get functionsLoaderName() {
      return 'getFragmentExtensionFunctions';
    }

    /**
     * The name of the runtime environment function to be used to load the descriptor.
     * @type {string}
     */
    get fullName() {
      return `${this.id}-fragment`;
    }

    /**
     * Returns the ExtensionContext constructor used to create the '$' expression context.
     * @type {FragmentExtensionContext}
     */
    static get ContextType() {
      return FragmentExtensionContext;
    }

    /**
     * The resourceName can be different from the name (which generally is the 'id'). Container, when it loads a
     * specific resource must use this property as the actual name of the resource that this fragment represents.
     * Example: fragment id is unique foo1/foo2 etc. Each instance of fragment loads the same resource 'foo-fragment.*'
     * @type {String}
     */
    get resourceName() {
      return this.base.resourceName;
    }

    /**
     * This is called by ConfigurableMetadataProviderHelper._createExtensionModels when there is
     * dynamic container in the FragmentExtension.
     *
     * @returns {Promise<Object>} Returns an instance of FragmentModuleViewModel instance.
     */
    getViewModel() {
      return Promise.resolve()
        .then(() => new FragmentModuleViewModel(this));
    }

    /**
     * Returns a scope resolver map where keys are scope name ("fragment")
     * and value the matching objects. This is used to build the scopeResolver object.
     *
     * @private
     * @return {Object} an object which properties are scope
     */
    getScopeResolverMap() {
      return {
        [Constants.FRAGMENT_PREFIX]: this,
      };

      // TODO: unclear how to setup namespace prefixes for application and global
      // return {
      //  [Constants.GLOBAL_PREFIX]: this,
      //  [Constants.APPLICATION_PREFIX]: this,
      // };

      // // if the parent of the FragmentExtension is defined we need to merge scope resolvers
      // // so that the FragmentExtension has access to its parent's scopes
      // if (this.parent) {
      //   return Object.assign(map, this.parent.getScopeResolverMap());
      // }
      //
      // return map;
    }

    /**
     * Used by event processing. For FragmentExtension containers, we start (and end) with the Fragment itself.
     * @see FireCustomEventAction
     *
     * @overrides Container.getLeafContainer
     * @returns {Fragment}
     * @override
     */
    // eslint-disable-next-line class-methods-use-this
    getLeafContainer() {
      return this.base;
    }

    /**
     * delegates to base
     * @param options
     * @return {string|undefined}
     */
    // eslint-disable-next-line class-methods-use-this
    validateNavigation(options) {
      return this.base.validateNavigation(options);
    }
  }

  return FragmentExtension;
});

